import React, { useContext, useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import CustomTooltip from "../../../components/CustomTooltip";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HolyConfirmationLinkCertificateModal from "../../../modals/holy-confirmation/HolyConfirmationLinkCertificateModal";
import GeneratedHolyConfirmationCertmodal from "../../../modals/holy-confirmation/GeneratedHolyConfirmationCertmodal";
import warningimg from "../../../assets/img/warning.png";
import EditOutsiderHolyConfirmationDetailsModal from "../../../modals/holy-confirmation/EditOutsiderHolyConfirmationDetailsModal";
import Swal from "sweetalert2";
import { AuthContext } from "react-oauth2-code-pkce";
import maleImg from "../../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../../assets/img/women_delhi_diocese_theme.png";

const ConfirmationListRow = ({
  confirmation,
  i,
  commonAPIRequest,
  callGetAllHolyConfirmation,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentHolyConfirmation, setCurrentHolyConfirmation] = useState();
  const { churchDetails } = useSelector((state) => state?.church);
  const [confirmationLinkCertModal, setConfirmationLinkCertModal] =
    useState(false);
  const [loading2, setLoading2] = useState(false);
  const [generateConfirmationCertModal, setGenerateConfirmationCertModal] =
    useState(false);
  const [
    editHolyConfirmationOutsiderModal,
    setEditHolyConfirmationOutsiderModal,
  ] = useState(false);

  const { tokenData } = useContext(AuthContext);
  const deleteCommunionPermission = tokenData?.permissions?.includes(
    "DELETE_CONFIRMATION"
  );

  // call generate pdf certificate api for confirmation start

  // const callGetHolyConfirmationCertificateApi = (id) => {
  //   let serviceParams = {
  //     api: `${API.GENERATE_HOLY_CONFIRMATION_CERTIFICATE}?confirmationId=${id}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         setCertPdf(result?.payload);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  // call generate pdf certificate api for confirmation end

  const callGetHolyConfirmationDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_DETAILS}?confirmationId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          setCurrentHolyConfirmation(result?.payload);
          setConfirmationLinkCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetHolyConfirmationDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_DETAILS}?confirmationId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCurrentHolyConfirmation(result?.payload);
          setGenerateConfirmationCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetHolyConfirmationDetails = (id) => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_DETAILS}?confirmationId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          setCurrentHolyConfirmation(result?.payload);
          setEditHolyConfirmationOutsiderModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Confirmation start

  const callDeleteConfirmationApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_HOLY_CONFIRMATION}?confirmationId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Confirmation has been deleted.",
            icon: "success",
          });
          callGetAllHolyConfirmation();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Delete a Confirmation start

  return (
    <tr key={confirmation?.confirmationId}>
      <th scope="row">{i + 1}</th>
      <td className="fw-bold">
        <img
          style={{ height: 36, width: 36 }}
          src={confirmation?.gender === "Male" ? maleImg : femaleImg}
          className="img-fluid me-2"
          alt="..."
        />
        {confirmation.memberId ? (
          <Link
            to={"/admin/member-details"}
            state={{
              memberId: confirmation.memberId,
              back: true,
            }}
          >
            {confirmation.middleName
              ? `${confirmation.firstName} ${confirmation.middleName} ${confirmation.lastName}`
              : `${confirmation.firstName} ${confirmation.lastName}`}
          </Link>
        ) : confirmation.middleName ? (
          `${confirmation.firstName} ${confirmation.middleName} ${confirmation.lastName}`
        ) : (
          `${confirmation.firstName} ${confirmation.lastName}`
        )}
        {confirmation?.memberId && (
          <Badge className="ms-2" bg="primary" pill>
            <i className="ri-links-line me-1"></i>
            Linked
          </Badge>
        )}
      </td>
      <td
        style={{ height: 55 }}
        className="d-flex align-items-center justify-content-start"
      >
        <span style={{ width: 90 }}>
          {new Date(confirmation?.confirmationDate).toLocaleDateString(
            "en-GB",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
            }
          )}
        </span>
        {!confirmation?.confirmationDateAccurate && (
          <CustomTooltip label={"Might be Incorrect"} placement={"top"}>
            <Badge style={{ padding: 5 }} className="ms-1 fw-bold" bg="light">
              <img height={18} src={warningimg} alt="warning" />
            </Badge>
          </CustomTooltip>
        )}
      </td>
      <td>
        {confirmation?.regReferenceNum ? confirmation?.regReferenceNum : "-"}
      </td>
      <td>
        <Button
          disabled={confirmation?.extConfirmation}
          variant="primary"
          onClick={() => {
            callGetHolyConfirmationDetailsForLink(confirmation?.confirmationId);
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-download-2-fill"></i>
          )}
        </Button>

        <Button
          className="ms-2"
          disabled={confirmation?.extHolyCommunion}
          variant="warning"
          onClick={() => {
            if (confirmation?.memberId) {
              Swal.fire(
                "Update From Profile",
                `Please update Holy Confirmation Details from the user's profile. As he/she is the member of the parish.`,
                "warning"
              );
            } else {
              callGetHolyConfirmationDetails(confirmation?.confirmationId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-edit-2-fill"></i>
          )}
        </Button>

        {deleteCommunionPermission && (
          <CustomTooltip label={"Delete Confirmation"} placement={"top"}>
            <Button
              onClick={() =>
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    callDeleteConfirmationApi(confirmation.confirmationId);
                  }
                })
              }
              variant="danger"
              className="ms-2"
            >
              <i className="ri-delete-bin-6-line"></i>
            </Button>
          </CustomTooltip>
        )}

        {/* <Button
          variant="primary"
          className="ms-2"
          onClick={() => {
            callGetHolyConfirmationDetailsForPreview(
              confirmation?.confirmationId
            );
          }}
        >
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <i className="ri-eye-line"></i>
          )}
        </Button> */}

        {/*  */}
      </td>

      <HolyConfirmationLinkCertificateModal
        show={confirmationLinkCertModal}
        setShow={setConfirmationLinkCertModal}
        churchDetails={churchDetails}
        currentHolyConfirmation={currentHolyConfirmation}
      />

      <GeneratedHolyConfirmationCertmodal
        show={generateConfirmationCertModal}
        setShow={setGenerateConfirmationCertModal}
        churchDetails={churchDetails}
        currentHolyConfirmation={currentHolyConfirmation}
      />

      <EditOutsiderHolyConfirmationDetailsModal
        show={editHolyConfirmationOutsiderModal}
        setShow={setEditHolyConfirmationOutsiderModal}
        currentHolyConfirmation={currentHolyConfirmation}
        callGetAllHolyConfirmation={callGetAllHolyConfirmation}
      />
    </tr>
  );
};

export default withAPIRequest(ConfirmationListRow);
