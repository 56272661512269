import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ZoneFamilyTablePlaceholder from "../../components/placeholders/ZoneFamilyTablePlaceholder";
import { useFormik } from "formik";
import * as Yup from "yup";
import BaptismListRow from "./baptism-components/BaptismListRow";
import BaptismCertificateFilterModal from "./baptism-components/BaptismCertificateFilterModal";
import { BaptismsAction } from "../../store/slices/BaptismsSlice";
import { useDispatch, useSelector } from "react-redux";
import AddOutsiderBaptismDetailsModal from "../../modals/baptism/AddOutsiderBaptismDetailsModal";
import GeneratedBaptismCertificateModal from "../../modals/baptism/GeneratedBaptismCertificateModal";
import { Link } from "react-router-dom";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";
import Swal from "sweetalert2";
import CustomTooltip from "../../components/CustomTooltip";
import warningimg from "../../assets/img/warning.png";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required").min(3),
});

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

const BaptismList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const [baptismFilterModalVisible, setBaptismFilterModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const { baptisms, totalCount, present, pageNumber } = useSelector(
    (state) => state.baptisms
  );
  const { churchDetails } = useSelector((state) => state?.church);
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);
  const [baptismOutsiderModal, setBaptismOutsiderModal] = useState(false);
  const { tokenData } = useContext(AuthContext);

  const baptismPermission = tokenData?.permissions?.includes("READ_BAPTISM");

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      if (isNaN(values?.name)) {
        const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
          return e.trim().length > 0;
        });
        fullNameArray.length === 1
          ? callSearchBaptismApi(fullNameArray[0], "")
          : callSearchBaptismApi(
              fullNameArray[0],
              fullNameArray[fullNameArray.length - 1]
            );
      } else {
        callSearchBaptismByNumberApi(+values?.name);
      }
    },
  });

  // api to call baptism cert api start

  const callGetAllBaptism = () => {
    let serviceParams = {
      api: `${API.BAPTISM_LIST}?pgNum=${pageNumber}&pgSize=10&order=desc`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            BaptismsAction.getBaptisms(
              result?.payload?.basicBaptismDetailList
                ? result?.payload?.basicBaptismDetailList
                : []
            )
          );
          dispatch(BaptismsAction.getTotalCount(result?.payload.totalCount));
          setCurrentPageNumber(pageNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call baptism cert api end

  // api to call baptism cert api on date start

  const callGetAllBaptismByDate = (startDate, endDate) => {
    let serviceParams = {
      api: `${API.BAPTISM_LIST}?pgNum=${pageNumber}&pgSize=10&startDate=${startDate}&endDate=${endDate}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            BaptismsAction.getBaptisms(
              result?.payload?.basicBaptismDetailList
                ? result?.payload?.basicBaptismDetailList
                : []
            )
          );
          dispatch(BaptismsAction.getTotalCount(result?.payload.totalCount));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call baptism cert api on date end

  // api to search baptism details by name start

  const callSearchBaptismApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${
        API.SEARCH_BAPTISM
      }?firstName=${firstName.trim()}${`&lastName=${lastName.trim()}`}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            BaptismsAction.getBaptisms(result?.payload ? result?.payload : [])
          );
          dispatch(BaptismsAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search baptism details by name end

  const callSearchBaptismByNumberApi = (Id) => {
    let serviceParams = {
      api: `${API.SEARCH_BAPTISM}?regReferenceNum=${Id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            BaptismsAction.getBaptisms(result?.payload ? result?.payload : [])
          );
          dispatch(BaptismsAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!present && baptismPermission) {
      callGetAllBaptism();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== pageNumber) {
      callGetAllBaptism();
    }
  }, [pageNumber]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4">
        {!baptismPermission ? (
          <NotAuthenticated />
        ) : (
          <>
            <div className="d-flex mb-3 justify-content-between align-items-center">
              <div>
                <h5 id="section3" className="main-subtitle m-0">
                  Baptism Certificate Archive
                </h5>
                <p>
                  {" "}
                  Recent Baptism Details in{" "}
                  <span className="fw-bold text-primary text-decoration-underline">
                    {churchDetails?.churchName}
                  </span>
                </p>
              </div>

              <Button
                variant="light"
                onClick={() => {
                  callGetAllBaptism();
                }}
                style={{ height: 40 }}
              >
                <i className="ri-refresh-line"></i>
              </Button>
            </div>
            {/* <p>
          Add <code>hover</code> props to enable a hover state on table rows
          within a tbody.
        </p> */}

            <div className="form-search py-2  ">
              <i className="ri-search-line"></i>
              <Form.Control
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
                type="text"
                placeholder="e.g. Joseph George"
                value={formik.values.name}
              />
              <Button onClick={formik.handleSubmit} variant="primary">
                Search
              </Button>
            </div>
            <hr className="hr" />

            {/*  */}
            <div className="d-lg-none">
              <Row className="g-1 g-sm-2 g-xl-3 mb-5">
                <div className="main-label-group mb-3">
                  <h3 className="fw-bold text-primary">Results</h3>
                  <div>
                    <Button
                      onClick={() => {
                        setBaptismFilterModalVisible(true);
                      }}
                      variant="outline-primary"
                    >
                      Filter
                    </Button>
                  </div>
                </div>
                {baptisms.map((baptism) => (
                  <Col xs="6" sm="4" md="3" xl key={baptism?.baptismId}>
                    <Card className="card-file">
                      <div
                        onClick={() => {
                          Swal.fire({
                            title: "Warning!",
                            text: "Certificates pdf's can only be generated / edited on the desktop.",
                            imageUrl: require("../../assets/img/on-desktop.png"),
                            imageWidth: 300,
                            imageHeight: 250,
                            imageAlt: "Custom image",
                          });
                        }}
                        className={"card-file-icon primary"}
                      >
                        {!baptism?.memberId && (
                          <Badge
                            style={{ position: "absolute", top: 18, right: 18 }}
                            className="ms-2 fw-bold"
                            bg="secondary"
                            pill
                          >
                            Non Member
                          </Badge>
                        )}

                        <i className="ri-file-pdf-line"></i>
                      </div>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <img
                            style={{ height: 48, width: 48 }}
                            src={
                              baptism?.gender === "Male" ? maleImg : femaleImg
                            }
                            className="img-fluid"
                            alt="..."
                          />

                          <h6 className="ms-2 mt-2 fw-bold">
                            {baptism.memberId ? (
                              <Link
                                to={"/admin/member-details"}
                                state={{
                                  memberId: baptism.memberId,
                                  back: true,
                                }}
                                className="link-02 fw-bold text-primary"
                              >
                                {baptism.middleName
                                  ? `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
                                  : `${baptism.firstName} ${baptism.lastName}`}
                              </Link>
                            ) : baptism.middleName ? (
                              `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
                            ) : (
                              `${baptism.firstName} ${baptism.lastName}`
                            )}
                            <div className="d-flex">
                              <span className="my-1">
                                {new Date(
                                  baptism?.baptismDate
                                ).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </span>
                              {!baptism?.baptismDateAccurate && (
                                <CustomTooltip
                                  label={"Date Might be Incorrect"}
                                  placement={"top"}
                                >
                                  <Badge
                                    className="ms-2 fw-bold p-0"
                                    bg="light"
                                  >
                                    <img
                                      height={18}
                                      src={warningimg}
                                      alt="warning"
                                    />
                                  </Badge>
                                </CustomTooltip>
                              )}
                            </div>
                          </h6>
                        </div>

                        {/* <span>{file.size}</span> */}
                      </Card.Body>
                      <hr className="my-1" />
                      <Card.Footer className=" pt-2 ps-2 bg-light">
                        <span>Reference Number: </span>
                        <p className="text-primary fw-bold">
                          {baptism?.referenceNum}
                        </p>
                      </Card.Footer>
                    </Card>
                  </Col>
                ))}
                <pre>
                  {Math.ceil(totalCount / 10) > 1 && (
                    <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                      <Pagination.Item
                        disabled={pageNumber === 1}
                        onClick={() =>
                          dispatch(BaptismsAction.setPageNumber(1))
                        }
                      >
                        First
                      </Pagination.Item>

                      {getPaginationRange(
                        Math.ceil(totalCount / 10),
                        pageNumber,
                        3
                      ).map((n, i) => {
                        return (
                          <Pagination.Item
                            key={i}
                            onClick={() => {
                              dispatch(BaptismsAction.setPageNumber(n));
                            }}
                            active={pageNumber === n}
                          >
                            {n}
                          </Pagination.Item>
                        );
                      })}

                      <Pagination.Item
                        onClick={() => {
                          dispatch(
                            BaptismsAction.setPageNumber(
                              Math.ceil(totalCount / 10)
                            )
                          );
                        }}
                      >
                        Last
                      </Pagination.Item>
                    </Pagination>
                  )}
                </pre>
                {!loading && baptisms.length === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3
                      style={{ backgroundColor: "#fff" }}
                      className="text-center py-3 fw-bold"
                    >
                      No Baptism's Found
                    </h3>
                  </div>
                )}
              </Row>
            </div>
            {/*  */}

            <Card.Footer className="d-none d-lg-inline">
              <pre>
                <div className="main-label-group mb-3">
                  <label>Results</label>
                  <div>
                    <Button
                      onClick={() => {
                        setBaptismFilterModalVisible(true);
                      }}
                      className="mx-3"
                      variant="outline-primary"
                    >
                      Filter
                    </Button>
                    {/* <Button
                  className="mx-3"
                  onClick={() => {
                    callGetAllBaptism();
                    formik.resetForm();
                  }}
                  variant="outline-primary"
                >
                  Reset
                </Button> */}
                    <Button
                      onClick={() => {
                        setBaptismOutsiderModal(true);
                      }}
                      variant="primary"
                    >
                      Add
                    </Button>
                  </div>
                </div>
                <Card className="card-baptism">
                  <Card.Body>
                    <Table hover className="mb-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Baptism Date</th>
                          <th scope="col">Reg. Ref. Num</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {loading && <ZoneFamilyTablePlaceholder />}
                      {!loading && (
                        <tbody>
                          {baptisms?.map((baptism, i) => (
                            <BaptismListRow
                              baptism={baptism}
                              i={(pageNumber - 1) * 10 + i}
                              key={i}
                              callGetAllBaptism={callGetAllBaptism}
                            />
                          ))}
                        </tbody>
                      )}
                    </Table>

                    <pre>
                      {Math.ceil(totalCount / 10) > 1 && (
                        <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                          <Pagination.Item
                            disabled={pageNumber === 1}
                            onClick={() =>
                              dispatch(BaptismsAction.setPageNumber(1))
                            }
                          >
                            First
                          </Pagination.Item>

                          {getPaginationRange(
                            Math.ceil(totalCount / 10),
                            pageNumber,
                            3
                          ).map((n, i) => {
                            return (
                              <Pagination.Item
                                key={i}
                                onClick={() => {
                                  dispatch(BaptismsAction.setPageNumber(n));
                                }}
                                active={pageNumber === n}
                              >
                                {n}
                              </Pagination.Item>
                            );
                          })}

                          <Pagination.Item
                            onClick={() => {
                              dispatch(
                                BaptismsAction.setPageNumber(
                                  Math.ceil(totalCount / 10)
                                )
                              );
                            }}
                          >
                            Last
                          </Pagination.Item>
                        </Pagination>
                      )}
                    </pre>

                    {!loading && baptisms.length === 0 && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                          }}
                          className="text-center"
                        >
                          <Lottie
                            style={{ width: 180, height: 180 }}
                            animationData={noSearchAnimation}
                            loop={true}
                          />
                        </div>
                        <h3
                          style={{ backgroundColor: "#fff" }}
                          className="text-center py-3 fw-bold"
                        >
                          No Baptism's Found
                        </h3>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </pre>
            </Card.Footer>
          </>
        )}
      </div>

      <BaptismCertificateFilterModal
        show={baptismFilterModalVisible}
        setShow={setBaptismFilterModalVisible}
        callGetAllBaptismByDate={callGetAllBaptismByDate}
      />
      <AddOutsiderBaptismDetailsModal
        show={baptismOutsiderModal}
        setShow={setBaptismOutsiderModal}
        callGetAllBaptism={callGetAllBaptism}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(BaptismList);
