import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import DateInput from "../../components/form/DateInput";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import { useFormik } from "formik";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import Swal from "sweetalert2";
import SelectInput from "../../components/form/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { ZonesAction } from "../../store/slices/ZonesSlice";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

function capitalizeFirstLetterOfEachWord(sentence) {
  return sentence
    .split(" ") // Split the sentence into an array of words
    .map(
      (
        word // Map over each word in the array
      ) =>
        word.charAt(0).toUpperCase() + // Capitalize the first letter of the word
        word.slice(1).toLowerCase() // Add the rest of the word in lowercase
    )
    .join(" "); // Join the array of words back into a single string
}

const EditFamilyBasicDetailsModal = ({
  show,
  setShow,
  basicDetails,
  commonAPIRequest,
  familyId,
  callGetFamilyDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const { zones, present } = useSelector((state) => state.zones);
  const dispatch = useDispatch();
  const [subZones, setSubZones] = useState([]);

  const { tokenData } = useContext(AuthContext);
  const updateFamilyPermission =
    tokenData?.permissions?.includes("UPDATE_FAMILY");

  const formik = useFormik({
    initialValues: {
      zone: "",
      subZone: "",
      dateOfRegistration: "",
      homeParish: "",
      lastParish: "",
      csf: "",
      address: "",
      remark: "",
    },
    onSubmit: () => {
      callUpdateFamilyDetails();
    },
  });

  const callGetAllZones = () => {
    let serviceParams = {
      api: API.GET_ALL_ZONES,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            ZonesAction.getZones(
              result?.payload.map((zone) => {
                return {
                  label: zone.zoneName,
                  value: zone.zoneId,
                  zoneDetails: zone,
                };
              })
            )
          );
          // formik1.setFieldValue("zone", result?.payload[0].zoneId);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetSubZones = () => {
    let serviceParams = {
      api: `${API.GET_ALL_SUB_ZONES}?zoneDetailId=${formik.values.zone}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    // setSubZoneLoader(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        // setSubZoneLoader(false);
        try {
          setSubZones(
            result?.payload.map((subZone) => {
              return {
                label: subZone.address,
                value: subZone.subZoneId,
              };
            })
          );
          formik.setFieldValue("subZone", result?.payload[0].subZoneId);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // setSubZoneLoader(false);
      }
    });
  };

  useEffect(() => {
    if (!present) {
      callGetAllZones();
    }
  }, []);

  useEffect(() => {
    if (formik.values.zone) {
      callGetSubZones();
    }
  }, [formik.values.zone]);

  // update basic family details start
  const callUpdateFamilyDetails = () => {
    let serviceParams = {
      api: `${API.GET_FAMILY_DETAILS}?familyId=${familyId}`,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        familyDetail: {
          familyId: familyId,
          monthlySupportFund: formik.values.csf,
          active: status,
          dateOfRegistration: formik.values.dateOfRegistration,
          homeParish: capitalizeFirstLetterOfEachWord(formik.values.homeParish),
          lastParish: capitalizeFirstLetterOfEachWord(formik.values.lastParish),
          address: {
            addressLine1: formik.values.address,
            addressLine2: "",
            block: null,
            sector: null,
            city: "",
            state: "",
            pinCode: "",
          },
          remark: formik?.values?.remark,
        },
        newSubZoneId: formik.values.subZone,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire(
            "Success",
            `Basic Details Updated Successfully.`,
            "success"
          );
          callGetFamilyDetails();
          setShow(false);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // update basic family details end

  useEffect(() => {
    if (show) {
      formik.setFieldValue(
        "dateOfRegistration",
        new Date(basicDetails?.dateOfRegistration)
      );
      formik.setFieldValue("homeParish", basicDetails?.homeParish);
      formik.setFieldValue("lastParish", basicDetails?.lastParish);
      formik.setFieldValue("csf", basicDetails?.monthlySupportFund);
      formik.setFieldValue("address", basicDetails?.address?.addressLine1);
      formik.setFieldValue("remark", basicDetails?.remark);
      setStatus(basicDetails?.active);
    }
  }, [show]);

  useEffect(() => {
    if (basicDetails?.zoneName) {
      formik.setFieldValue(
        "zone",
        zones?.find((z) => z.label === basicDetails?.zoneName)?.value
      );
    }
  }, [basicDetails?.zoneName]);

  console.log("subzone-id", basicDetails?.subZoneId, basicDetails?.zoneName);

  useEffect(() => {
    if (subZones?.length !== 0) {
      formik.setFieldValue("subZone", basicDetails?.subZoneId);
    }
  }, [subZones]);

  useEffect(() => {
    if (show) {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          formik.handleSubmit();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold text-primary">
          Update Family Basic Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <Lottie
              style={{ width: 200, height: 200 }}
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : !updateFamilyPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <Row>
            <Col sm={12} className="justify-content-end align-items-end d-flex">
              <Form.Check
                type="switch"
                checked={status}
                label="Active?"
                onChange={(e) => {
                  setStatus(e.target.checked);
                }}
              />
            </Col>
            <Col sm={6}>
              <SelectInput
                selectOptions={zones}
                label={"BEC Unit Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"zone"}
                id={"zone"}
                value={formik.values.zone}
                error={formik.errors.zone}
                touched={formik.touched.zone}
              />
            </Col>
            <Col sm={6}>
              <SelectInput
                selectOptions={subZones}
                label={"Area Name"}
                setFieldValue={formik.setFieldValue}
                keyword={"subZone"}
                id={"subZone"}
                value={formik.values.subZone}
                error={formik.errors.subZone}
                touched={formik.touched.subZone}
                // disabled={formik.values.zone ? false : true}
              />
            </Col>
            <Col sm={12}>
              <DateInput
                label={"Date Of Registration"}
                setFieldValue={formik.setFieldValue}
                keyword={"dateOfRegistration"}
                value={formik.values.dateOfRegistration}
                error={formik.errors.dateOfRegistration}
                touched={formik.touched.dateOfRegistration}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Home Parish"}
                setFieldValue={formik.setFieldValue}
                keyword={"homeParish"}
                value={formik.values.homeParish}
                error={formik.errors.homeParish}
                touched={formik.touched.homeParish}
              />
            </Col>
            <Col sm={6}>
              <TextInput
                label={"Last Parish"}
                setFieldValue={formik.setFieldValue}
                keyword={"lastParish"}
                value={formik.values.lastParish}
                error={formik.errors.lastParish}
                touched={formik.touched.lastParish}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={"Monthly C.S.F Contribution"}
                setFieldValue={formik.setFieldValue}
                keyword={"csf"}
                value={formik.values.csf}
                error={formik.errors.csf}
                touched={formik.touched.csf}
              />
            </Col>
            <Col sm={12}>
              <TextArea
                label={"Residential Address"}
                setFieldValue={formik.setFieldValue}
                keyword={"address"}
                value={formik.values.address}
                error={formik.errors.address}
                touched={formik.touched.address}
              />
            </Col>
            <Col sm={12}>
              <TextArea
                label={"Remark"}
                placeholder={"Add any remark..."}
                id={"remark"}
                setFieldValue={formik.setFieldValue}
                keyword={"remark"}
                value={formik.values.remark}
                error={formik.errors.remark}
                touched={formik.touched.remark}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {updateFamilyPermission && !loading && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(EditFamilyBasicDetailsModal);
