import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import * as Yup from "yup";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import ZonePlaceholder from "../../components/placeholders/ZonePlaceholder";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";
import CustomTooltip from "../../components/CustomTooltip";
import warningimg from "../../assets/img/warning.png";
import Swal from "sweetalert2";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";

function compareStrings(str1, str2) {
  // Convert both strings to lowercase to make the comparison case-insensitive
  const lowerStr1 = str1.toLowerCase();
  const lowerStr2 = str2.toLowerCase();

  // Check for exact match
  if (lowerStr1 === lowerStr2) {
    return "Exact Match";
  }

  // Check for partial match (if one string is contained in the other)
  if (lowerStr1.includes(lowerStr2) || lowerStr2.includes(lowerStr1)) {
    return "Partial Match";
  }

  // If neither exact nor partial match, return no match
  return "No Match";
}

const alphabetsOnly = /^[a-zA-Z. ,]*$/;

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed.")
    .min(3),
});

//

const ComparisonDetails = ({
  basicDetails,
  currentBaptism,
  compareStrings,
}) => {
  const ComparisonField = ({ label, value1, value2, compareResult }) => {
    const getMatchIcon = (result) => {
      switch (result) {
        case "Exact Match":
          return "ri-checkbox-circle-fill";
        case "Partial Match":
          return "ri-error-warning-fill";
        default:
          return "ri-close-circle-fill";
      }
    };

    const getMatchColor = (result) => {
      switch (result) {
        case "Exact Match":
          return "success";
        case "Partial Match":
          return "warning";
        default:
          return "danger";
      }
    };

    return (
      <Row className="mb-4 align-items-center">
        <Col xs={12}>
          <h6 className="text-muted mb-3 text-uppercase text-center">
            {label}
          </h6>
        </Col>
        <Col xs={5}>
          <Card className="bg-light border-0">
            <Card.Body className="p-3 text-center">
              <p className="mb-0 text-dark" style={{ fontSize: "1rem" }}>
                {value1}
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={2} className="text-center">
          <Badge
            bg={getMatchColor(compareResult)}
            className="p-2 rounded-circle"
          >
            <i
              style={{ fontSize: "1.5rem" }}
              className={getMatchIcon(compareResult)}
            ></i>
          </Badge>
        </Col>
        <Col xs={5}>
          <Card className="bg-light border-0">
            <Card.Body className="p-3 text-center">
              <p className="mb-0 text-dark" style={{ fontSize: "1rem" }}>
                {value2}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <Card className="shadow-sm">
      <Card.Body className="p-4">
        <Row className="mb-4">
          <Col sm={5}>
            <h5 className="text-primary text-center mb-4">
              Personal Information
            </h5>
          </Col>
          <Col sm={2}>
            <h5 className="text-center mb-4">Match</h5>
          </Col>
          <Col sm={5}>
            <h5 className="text-primary text-center mb-4">
              Baptism Information
            </h5>
          </Col>
        </Row>

        <ComparisonField
          label="Full Name"
          value1={`${basicDetails?.firstName} ${basicDetails?.middleName} ${basicDetails?.lastName}`}
          value2={`${currentBaptism?.firstName} ${currentBaptism?.middleName} ${currentBaptism?.lastName}`}
          compareResult={compareStrings(
            basicDetails?.firstName,
            currentBaptism?.firstName
          )}
        />

        <ComparisonField
          label="Date of Birth"
          value1={new Date(basicDetails?.dateOfBirth).toLocaleDateString(
            "en-GB",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
            }
          )}
          value2={new Date(currentBaptism?.dob).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
          compareResult={compareStrings(
            basicDetails?.dateOfBirth,
            currentBaptism?.dob
          )}
        />

        <ComparisonField
          label="Father's Name"
          value1={basicDetails?.fatherName}
          value2={currentBaptism?.fatherName}
          compareResult={compareStrings(
            basicDetails?.fatherName,
            currentBaptism?.fatherName
          )}
        />

        <ComparisonField
          label="Mother's Name"
          value1={basicDetails?.motherName}
          value2={currentBaptism?.motherName}
          compareResult={compareStrings(
            basicDetails?.motherName,
            currentBaptism?.motherName
          )}
        />

        <ComparisonField
          label="Gender"
          value1={basicDetails?.gender}
          value2={currentBaptism?.gender}
          compareResult={compareStrings(
            basicDetails?.gender,
            currentBaptism?.gender
          )}
        />
      </Card.Body>
    </Card>
  );
};
//
const LinkOutsiderBaptism = ({
  show,
  setShow,
  commonAPIRequest,
  basicDetails,
  memberId,
  getFamilyMemberDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [baptisms, setBaptisms] = useState([]);
  const [curretStep, setCurretStep] = useState(1);
  const [currentBaptism, setCurrentBaptism] = useState();

  const { tokenData } = useContext(AuthContext);
  const updateBaptismPermission =
    tokenData?.permissions?.includes("UPDATE_BAPTISM");

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchBaptismApi(fullNameArray[0], "")
        : callSearchBaptismApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  const callGetBaptismDetails = (baptismId) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${baptismId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setCurretStep(2);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search baptism details by name start

  const callSearchBaptismApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${
        API.SEARCH_BAPTISM
      }?nonMember=${true}&firstName=${firstName.trim()}${
        lastName && `&lastName=${lastName.trim()}`
      }`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          console.log("result", result);
          setBaptisms(result?.payload);
          // dispatch(
          //   BaptismsAction.getBaptisms(result?.payload ? result?.payload : [])
          // );
          // dispatch(BaptismsAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search baptism details by name end

  // function to call API for Update New Baptism start

  const callUpdatedBaptismDetailsApi = (value) => {
    let serviceParams = {
      api: API.EXTERNAL_BAPTISM_API,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        baptismDetail: {
          ...currentBaptism,
        },
        memberId: memberId,
      },
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire("Success", `Baptism Linked Successfully.`, "success");
          setShow(false);
          getFamilyMemberDetails();
          formik.resetForm();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  //  function to call API for Update New Baptism end

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-primary fw-bold">
          Link Baptism Certificate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!updateBaptismPermission ? (
          <div
            style={{ height: 400 }}
            className="justify-content-center align-items-center d-flex"
          >
            <NotAuthenticated />
          </div>
        ) : (
          <>
            {curretStep === 1 && (
              <div>
                <Row className="g-5">
                  <Col xl>
                    <div className="form-search py-2  ">
                      <i className="ri-search-line"></i>
                      <Form.Control
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                        type="text"
                        placeholder="e.g. Joseph George"
                        value={formik.values.name}
                      />
                      <Button onClick={formik.handleSubmit} variant="primary">
                        Search
                      </Button>
                    </div>
                    <hr className="hr" />
                    <div className="mb-2">
                      {formik.errors.name && formik.touched.name && (
                        <p className="text-danger">*{formik.errors.name}</p>
                      )}
                    </div>
                  </Col>
                  {/* <p className="m-0 text-primary fw-bold" style={{ fontSize: 12 }}>
            Search the certificate for whom you want to link
          </p> */}
                </Row>

                {/*  */}
                <Row className="g-2 g-xxl-3 mb-5">
                  {loading && <ZonePlaceholder />}

                  <Card className="card-baptism">
                    <Card.Body>
                      <Table hover className="mb-0">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Baptism Date</th>
                            <th scope="col">Reg. Ref. Num</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {/* {loading && <ZoneFamilyTablePlaceholder />} */}
                        {!loading && (
                          <tbody>
                            {baptisms?.map((baptism, i) => (
                              <tr key={baptism?.baptismId}>
                                <th scope="row">{i + 1}</th>
                                <td className="fw-bold">
                                  {baptism.memberId ? (
                                    <Link
                                      to={"/admin/member-details"}
                                      state={{
                                        memberId: baptism.memberId,
                                        back: true,
                                      }}
                                    >
                                      {baptism.middleName
                                        ? `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
                                        : `${baptism.firstName} ${baptism.lastName}`}
                                    </Link>
                                  ) : baptism.middleName ? (
                                    `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
                                  ) : (
                                    `${baptism.firstName} ${baptism.lastName}`
                                  )}
                                  {!baptism?.memberId && (
                                    <Badge className="ms-2" bg="secondary" pill>
                                      Non Member
                                    </Badge>
                                  )}
                                </td>
                                <td
                                  style={{ height: 55 }}
                                  className="d-flex align-items-center justify-content-start"
                                >
                                  <span style={{ width: 90 }}>
                                    {new Date(
                                      baptism?.baptismDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </span>

                                  {!baptism?.baptismDateAccurate && (
                                    <CustomTooltip
                                      label={"Might be Incorrect"}
                                      placement={"top"}
                                    >
                                      <Badge
                                        style={{ padding: 5 }}
                                        className="ms-1 fw-bold"
                                        bg="light"
                                      >
                                        <img
                                          height={18}
                                          src={warningimg}
                                          alt="warning"
                                        />
                                      </Badge>
                                    </CustomTooltip>
                                  )}
                                </td>
                                <td>{baptism?.referenceNum}</td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      callGetBaptismDetails(baptism?.baptismId);
                                    }}
                                    disabled={baptism?.memberId}
                                  >
                                    <i className="ri-links-line"></i> Link
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </Table>
                    </Card.Body>
                  </Card>

                  {!loading && baptisms.length === 0 && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="text-center mt-5"
                      >
                        <Lottie
                          style={{ width: 180, height: 180 }}
                          animationData={noSearchAnimation}
                          loop={true}
                        />
                      </div>
                      <h3 className="text-center fw-bold">
                        No Certificate Found
                      </h3>
                      <h5 className="text-center fw-400">
                        Please Try With Another Name.
                      </h5>
                    </div>
                  )}
                </Row>
              </div>
            )}

            {curretStep === 2 && (
              <ComparisonDetails
                basicDetails={basicDetails}
                currentBaptism={currentBaptism}
                compareStrings={compareStrings}
              />
            )}
          </>
        )}
        {/*  */}
      </Modal.Body>
      {curretStep === 2 && (
        <Modal.Footer>
          <Button
            onClick={() => {
              setCurrentBaptism(null);
              setCurretStep(1);
            }}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              if (
                compareStrings(basicDetails?.gender, currentBaptism?.gender) ===
                  "Exact Match" &&
                compareStrings(
                  basicDetails?.dateOfBirth,
                  currentBaptism?.dob
                ) === "Exact Match"
              ) {
                callUpdatedBaptismDetailsApi();
              } else {
                Swal.fire(
                  "Warning",
                  "D.O.B and Gender should be exact match to link the certificate",
                  "warning"
                );
              }
            }}
          >
            Link
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default withAPIRequest(LinkOutsiderBaptism);
