import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Accordion, Button, Card, Pagination, Table } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import SelectInput from "../../components/form/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import AddExpenseModal from "../../modals/expenses/AddExpenseModal";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { useFormik } from "formik";
import { FinancialsAction } from "../../store/slices/FinancialsSlice";
import CustomPopover from "../../components/CustomPopover";
import ExpenseExcelReport from "../../components/excel-reports/ExpenseExcelReport";
import { AuthContext } from "react-oauth2-code-pkce";
import NotAuthenticated from "../../pages/NotAuthenticated";
import Swal from "sweetalert2";
import CustomTooltip from "../../components/CustomTooltip";

function generateArrayOfYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear - 10; year <= currentYear + 10; year++) {
    years.push({ label: year.toString(), value: year });
  }

  return years;
}

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

function sortByMonthWithZeroesFor12Months(arr) {
  // Create a new array with the sorted values
  const sortedArray = arr.slice().sort((a, b) => a.month - b.month);

  const result = [];

  for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
    const matchingObj = sortedArray.find((obj) => obj.month === monthIndex);
    result.push(matchingObj ? matchingObj.totalExpense : 0);
  }

  return result;
}

const monthArray = [
  { label: "JAN", value: 1 },
  { label: "FEB", value: 2 },
  { label: "MAR", value: 3 },
  { label: "APR", value: 4 },
  { label: "MAY", value: 5 },
  { label: "JUN", value: 6 },
  { label: "JUL", value: 7 },
  { label: "AUG", value: 8 },
  { label: "SEP", value: 9 },
  { label: "OCT", value: 10 },
  { label: "NOV", value: 11 },
  { label: "DEC", value: 12 },
];

const optionLine = {
  chart: {
    toolbar: {
      show: true,
    },

    zoom: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 4,
  },
  title: {
    text: "Expense Made by Month",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"],
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  colors: ["#912e2d"],
  tooltip: {
    y: {
      formatter: function (val) {
        return "₹ " + val + "";
      },
    },
  },
};

const ExpenseList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { churchDetails } = useSelector((state) => state?.church);
  const [addExpenseModal, setAddExpenseModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { tokenData } = useContext(AuthContext);
  const [yearChanged, setYearChanged] = useState(true);

  const readExpensePermission =
    tokenData?.permissions?.includes("READ_EXPENSE");
  const deleteExpensePermission =
    tokenData?.permissions?.includes("DELETE_EXPENSE");

  const dispatch = useDispatch();

  const {
    expenseList,
    expenseTotalCount,
    expenseListPresent,
    expensePageNumber,
    expenseMonth,
    expenseGraphData,
    expenseGraphDataPresent,
  } = useSelector((state) => state.financial);

  const [currentPageNumber, setCurrentPageNumber] = useState(expensePageNumber);

  const formik = useFormik({
    initialValues: {
      month: expenseMonth,
      currentYear: new Date().getFullYear(),
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  // call api for get all expenses

  const getAllExpensesApi = () => {
    let serviceParams = {
      api: `${API.EXPENSE_SUMMARY}?pgNum=${expensePageNumber}&pgSize=10&month=${formik.values.month}&year=${formik.values?.currentYear}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FinancialsAction.getExpenseList(
              result?.payload?.expenseDetailList
                ? result?.payload?.expenseDetailList
                : []
            )
          );
          dispatch(
            FinancialsAction.getExpenseTotalCount(result?.payload.totalCount)
          );
          setCurrentPageNumber(expensePageNumber);
          dispatch(FinancialsAction.setExpenseMonth(formik.values?.month));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all expenses

  useEffect(() => {
    if (!expenseListPresent && readExpensePermission) {
      getAllExpensesApi();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== expensePageNumber) {
      getAllExpensesApi();
    }
  }, [expensePageNumber]);

  useEffect(() => {
    if (formik.values?.month !== expenseMonth) {
      if (expensePageNumber !== 1) {
        dispatch(FinancialsAction.setExpensePageNumber(1));
      } else {
        getAllExpensesApi();
      }
    }
  }, [formik.values?.month]);

  // call api for get all expenses graph data

  const getAllExpensesGraphDataApi = () => {
    let serviceParams = {
      api: `${API.GET_EXPENSE_GRAPH_SUMMARY}?year=${formik.values?.currentYear}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FinancialsAction.setExpenseGraphData(
              result?.payload?.expenseDetailConsolidationList
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callDeleteExpenseApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_EXPENSE}?expenseId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Expense has been deleted.",
            icon: "success",
          });
          getAllExpensesApi();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all expenses graph data

  useEffect(() => {
    if (!expenseGraphDataPresent && readExpensePermission) {
      getAllExpensesGraphDataApi();
    }
  }, []);

  useEffect(() => {
    if (yearChanged) {
      getAllExpensesGraphDataApi();
      getAllExpensesApi();
      setYearChanged(false);
    }
  }, [formik.values?.currentYear]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        {!readExpensePermission ? (
          <NotAuthenticated />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 id="section3" className="main-subtitle m-0">
                  All Expenses
                </h5>
                <p>
                  List of all the Expenses of{" "}
                  <span className="fw-bold text-primary text-decoration-underline">
                    {churchDetails?.churchName}
                  </span>
                  .
                </p>
              </div>

              <div className="d-flex">
                <SelectInput
                  selectOptions={generateArrayOfYears()}
                  setFieldValue={formik.setFieldValue}
                  keyword={"currentYear"}
                  value={formik.values.currentYear}
                  error={formik.errors.currentYear}
                  touched={formik.touched.currentYear}
                  onChange={() => {
                    setYearChanged(true);
                  }}
                />
                <Button
                  variant="light"
                  onClick={() => {
                    getAllExpensesGraphDataApi();
                    getAllExpensesApi();
                  }}
                  style={{ height: 40 }}
                >
                  <i className="ri-refresh-line"></i>
                </Button>
              </div>
            </div>

            <Card className="card-expense">
              <Card.Body>
                <ReactApexChart
                  series={[
                    {
                      name: "Expense",
                      data: sortByMonthWithZeroesFor12Months(expenseGraphData),
                    },
                  ]}
                  options={optionLine}
                  type="bar"
                  height={350}
                />
              </Card.Body>
              <Card.Footer>
                <pre>
                  <p>Expenses Made For Each Month</p>
                </pre>
              </Card.Footer>
            </Card>

            <Card className="card-expense">
              <Card.Body className="d-lg-none pt-0 ">
                <div
                  className="mb-3 mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <h4 style={{ fontSize: 16 }} className="fw-bold">
                      Expenses for the month of{" "}
                      {
                        monthArray?.find(
                          (month) => month.value == formik.values.month
                        )?.label
                      }
                    </h4>
                  </div>
                  <div
                    style={{ width: "40%" }}
                    className="d-flex justify-content-end"
                  >
                    <SelectInput
                      selectOptions={monthArray}
                      setFieldValue={formik.setFieldValue}
                      keyword={"month"}
                      value={formik.values.month}
                      error={formik.errors.month}
                      touched={formik.touched.month}
                    />
                    <Button
                      onClick={() => {
                        setAddExpenseModal(true);
                      }}
                      variant="primary"
                      className="ms-3"
                    >
                      <i className="ri-add-line"></i>
                    </Button>
                  </div>
                </div>

                <hr />

                <Accordion defaultActiveKey="0">
                  {expenseList?.map((expense, index) => {
                    return (
                      <Accordion.Item
                        key={expense?.expenseId}
                        style={{
                          border: "1px solid #A05152",
                          // backgroundColor: "#F1F0F4",
                        }}
                        className="mb-2"
                        eventKey={index}
                      >
                        <Accordion.Header>
                          <div>
                            <h5 className="fw-bold mb-0">
                              ₹ {expense?.amount}
                            </h5>
                            <p style={{ fontSize: 12 }} className="m-0"></p>
                          </div>
                        </Accordion.Header>
                        <hr className="m-0" />
                        <Accordion.Body>
                          <p className="mb-2 mt-2">
                            <strong>Expense Amount:</strong>
                            <span className="text-primary">
                              {" "}
                              ₹ {expense?.amount}
                            </span>
                          </p>
                          <p className="mb-2 mt-2">
                            <strong>Payment Mode:</strong>
                            <span className="text-primary">
                              {" "}
                              {expense?.paymentMode}
                            </span>
                          </p>
                          <p className="mb-2 mt-2">
                            <strong>Expensed On:</strong>
                            <span className="text-primary">
                              {" "}
                              {new Date(
                                expense?.expenseDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              })}
                            </span>
                          </p>
                          <p className="mb-3 mt-2">
                            <strong>Purpose:</strong>
                            <span className="text-primary">
                              {" "}
                              {expense?.category}{" "}
                              {expense?.description &&
                                `(${expense?.description})`}
                            </span>
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
                {Math.ceil(expenseTotalCount / 10) > 1 && (
                  <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                    <Pagination.Item
                      disabled={expensePageNumber === 1}
                      onClick={() =>
                        dispatch(FinancialsAction.setCsfPageNumber(1))
                      }
                    >
                      First
                    </Pagination.Item>

                    {getPaginationRange(
                      Math.ceil(expenseTotalCount / 10),
                      expensePageNumber,
                      3
                    ).map((n, i) => {
                      return (
                        <Pagination.Item
                          key={i}
                          onClick={() => {
                            dispatch(FinancialsAction.setCsfPageNumber(n));
                          }}
                          active={expensePageNumber === n}
                        >
                          {i + 1}
                        </Pagination.Item>
                      );
                    })}

                    <Pagination.Item
                      onClick={() => {
                        dispatch(
                          FinancialsAction.setCsfPageNumber(
                            Math.ceil(expenseTotalCount / 10)
                          )
                        );
                      }}
                    >
                      Last
                    </Pagination.Item>
                  </Pagination>
                )}
              </Card.Body>

              <Card.Body className="d-none d-lg-inline">
                <div
                  className="mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h4 className="fw-bold">
                      Expenses for the month of{" "}
                      {
                        monthArray?.find(
                          (month) => month.value === formik.values.month
                        )?.label
                      }
                    </h4>
                  </div>
                  <div className="d-flex">
                    <SelectInput
                      selectOptions={monthArray}
                      setFieldValue={formik.setFieldValue}
                      keyword={"month"}
                      value={formik.values.month}
                      error={formik.errors.month}
                      touched={formik.touched.month}
                    />

                    {expenseList?.length !== 0 && (
                      <ExpenseExcelReport
                        totalCount={expenseTotalCount}
                        currentMonth={formik.values.month}
                      />
                    )}

                    <Button
                      onClick={() => {
                        setAddExpenseModal(true);
                      }}
                      variant="primary"
                      className="ms-3"
                    >
                      Add Expense
                    </Button>
                  </div>
                </div>
                <hr />
                {expenseList?.length === 0 && !loading ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3 className="text-center fw-bold">No Expenses Found</h3>
                    <p className="text-center">Please Add Expenses Details.</p>
                  </div>
                ) : loading ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 120, height: 120 }}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                    </div>

                    <p className="text-center">Loading...</p>
                  </div>
                ) : (
                  <>
                    <Table hover className="mb-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Mode Of Payment</th>
                          <th scope="col">Expense Made On</th>
                          {deleteExpensePermission && (
                            <th scope="col">Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {expenseList?.map((expense, i) => {
                          return (
                            <tr key={expense?.expenseId}>
                              <th scope="row">
                                {" "}
                                {(expensePageNumber - 1) * 10 + (i + 1)}
                              </th>
                              <td>
                                ₹ {expense?.amount}
                                <CustomPopover
                                  content={
                                    <p className="m-0 fs-16 text-primary">
                                      <strong>{expense?.category}</strong>
                                    </p>
                                  }
                                >
                                  <i className="ri-information-line text-primary fw-bold"></i>
                                </CustomPopover>
                              </td>
                              <td>{expense?.paymentMode}</td>
                              <td>
                                {new Date(
                                  expense?.expenseDate
                                ).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })}
                                <CustomPopover
                                  content={
                                    <p>
                                      Added by{" "}
                                      <strong>{expense?.createdBy}</strong> on{" "}
                                      <strong>
                                        {new Date(
                                          expense?.createTmStmp
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "long",
                                          year: "numeric",
                                        })}
                                      </strong>
                                    </p>
                                  }
                                >
                                  <i className="ri-information-line text-primary fw-bold"></i>
                                </CustomPopover>
                              </td>
                              {deleteExpensePermission && (
                                <td>
                                  <CustomTooltip
                                    label={"Delete Contribution"}
                                    placement={"top"}
                                  >
                                    <Button
                                      onClick={() =>
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "You won't be able to revert this!",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "Yes, delete it!",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            callDeleteExpenseApi(
                                              expense.expenseId
                                            );
                                          }
                                        })
                                      }
                                      variant="danger"
                                      className="ms-2"
                                    >
                                      <i className="ri-delete-bin-6-line"></i>
                                    </Button>
                                  </CustomTooltip>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                )}
                <pre>
                  {Math.ceil(expenseTotalCount / 10) > 1 && (
                    <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                      <Pagination.Item
                        disabled={expensePageNumber === 1}
                        onClick={() =>
                          dispatch(FinancialsAction.setExpensePageNumber(1))
                        }
                      >
                        First
                      </Pagination.Item>

                      {getPaginationRange(
                        Math.ceil(expenseTotalCount / 10),
                        expensePageNumber,
                        3
                      ).map((n, i) => {
                        return (
                          <Pagination.Item
                            key={i}
                            onClick={() => {
                              dispatch(
                                FinancialsAction.setExpensePageNumber(n)
                              );
                            }}
                            active={expensePageNumber === n}
                          >
                            {i + 1}
                          </Pagination.Item>
                        );
                      })}

                      <Pagination.Item
                        onClick={() => {
                          dispatch(
                            FinancialsAction.setExpensePageNumber(
                              Math.ceil(expenseTotalCount / 10)
                            )
                          );
                        }}
                      >
                        Last
                      </Pagination.Item>
                    </Pagination>
                  )}
                </pre>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
      <AddExpenseModal
        setShow={setAddExpenseModal}
        show={addExpenseModal}
        getAllExpensesApi={getAllExpensesApi}
        expenses={
          churchDetails
            ? churchDetails?.configurations
                .find((config) => config.name === "EXPENSE_CONFIGURATIONS")
                .value?.expenseCategories?.map((expense) => {
                  return {
                    label: expense,
                    value: expense,
                  };
                })
            : []
        }
      />
    </React.Fragment>
  );
};

export default withAPIRequest(ExpenseList);
