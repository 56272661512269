import React, { useContext, useState } from "react";
import {
  Badge,
  Button,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GeneratedBaptismCertificateModal from "../../../modals/baptism/GeneratedBaptismCertificateModal";
import BaptismLinkCertificateModal from "../../../modals/baptism/BaptismLinkCertificateModal";
import EditOutsiderBaptismDetailsModal from "../../../modals/baptism/EditOutsiderBaptismDetailsModal";
import Swal from "sweetalert2";
import CustomTooltip from "../../../components/CustomTooltip";
import warningimg from "../../../assets/img/warning.png";
import LinkOutsiderBaptism from "../../../modals/baptism/LinkOutsiderBaptism";
import { AuthContext } from "react-oauth2-code-pkce";
import maleImg from "../../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../../assets/img/women_delhi_diocese_theme.png";

const BaptismListRow = ({
  baptism,
  i,
  commonAPIRequest,
  callGetAllBaptism,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentBaptism, setCurrentBaptism] = useState();
  const { churchDetails } = useSelector((state) => state?.church);
  const [generatedBaptismModal, setGeneratedBaptismModal] = useState(false);
  const [baptismLinkCertModal, setBaptismLinkCertModal] = useState(false);
  const dispatch = useDispatch();
  const [loading2, setLoading2] = useState(false);
  const [editOutsiderEditBaptismModal, setEditOutsiderEditBaptismModal] =
    useState(false);

  const { tokenData } = useContext(AuthContext);
  const deleteBaptismPermission =
    tokenData?.permissions?.includes("DELETE_BAPTISM");
  // call generate pdf certificate api for baptism start

  // const callGetBaptismCertificateApi = (id) => {
  //   let serviceParams = {
  //     api: `${API.GENERATE_BAPTISM_CERTIFICATE}?baptismId=${id}`,
  //     method: AXIOS_METHOD_TYPES.GET,
  //     data: {},
  //   };
  //   setLoading(true);
  //   commonAPIRequest(serviceParams, async (result) => {
  //     if (result) {
  //       setLoading(false);
  //       try {
  //         setCertPdf(result?.payload);
  //       } catch (error) {
  //         console.log("error", error);
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  // call generate pdf certificate api for baptism end

  const callGetBaptismDetailsForLink = (id) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading2(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading2(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setBaptismLinkCertModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading2(false);
      }
    });
  };

  const callGetBaptismDetailsForPreview = (id) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setGeneratedBaptismModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callGetBaptismDetails = (id) => {
    let serviceParams = {
      api: `${API.BAPTISM_DETAILS}?baptismId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // console.log("result", result.payload);
          setCurrentBaptism(result?.payload);
          setEditOutsiderEditBaptismModal(true);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const callDeleteBaptismApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_BAPTISM}?baptismId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Baptism has been deleted.",
            icon: "success",
          });
          callGetAllBaptism();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <tr key={baptism?.baptismId}>
      <th scope="row">{i + 1}</th>
      <td className="fw-bold">
        <img
          style={{ height: 36, width: 36 }}
          src={baptism?.gender === "Male" ? maleImg : femaleImg}
          className="img-fluid me-2"
          alt="..."
        />
        {baptism.memberId ? (
          <Link
            to={"/admin/member-details"}
            state={{
              memberId: baptism.memberId,
              back: true,
            }}
          >
            {baptism.middleName
              ? `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
              : `${baptism.firstName} ${baptism.lastName}`}
          </Link>
        ) : baptism.middleName ? (
          `${baptism.firstName} ${baptism.middleName} ${baptism.lastName}`
        ) : (
          `${baptism.firstName} ${baptism.lastName}`
        )}
        {baptism?.memberId && (
          <Badge className="ms-2 fw-bold" bg="primary" pill>
            <i className="ri-links-line me-1"></i>
            Linked
          </Badge>
        )}
      </td>
      <td>{baptism?.gender}</td>
      <td
        style={{ height: 55 }}
        className="d-flex align-items-center justify-content-start"
      >
        <span style={{ width: 90 }}>
          {new Date(baptism?.baptismDate).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>

        {!baptism?.baptismDateAccurate && (
          <CustomTooltip label={"Might be Incorrect"} placement={"top"}>
            <Badge style={{ padding: 5 }} className="ms-1 fw-bold" bg="light">
              <img height={18} src={warningimg} alt="warning" />
            </Badge>
          </CustomTooltip>
        )}
      </td>
      <td>{baptism?.regReferenceNum ? baptism?.regReferenceNum : "-"}</td>
      <td>
        <Button
          disabled={baptism?.extBaptism}
          variant="primary"
          onClick={() => {
            callGetBaptismDetailsForLink(baptism?.baptismId);
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-download-2-fill"></i>
          )}
        </Button>

        <Button
          className="ms-2"
          disabled={baptism?.extBaptism}
          variant="warning"
          onClick={() => {
            if (baptism?.memberId) {
              Swal.fire(
                "Update From Profile",
                `Please update Baptism Details from the user's profile. As he/she is the member of the parish.`,
                "warning"
              );
            } else {
              callGetBaptismDetails(baptism?.baptismId);
            }
          }}
        >
          {loading2 ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <i className="ri-edit-2-fill"></i>
          )}
        </Button>

        {deleteBaptismPermission && (
          <CustomTooltip label={"Delete Baptism"} placement={"top"}>
            <Button
              onClick={() =>
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    callDeleteBaptismApi(baptism.baptismId);
                  }
                })
              }
              variant="danger"
              className="ms-2"
            >
              <i className="ri-delete-bin-6-line"></i>
            </Button>
          </CustomTooltip>
        )}
        {/* <Button
          variant="primary"
          className="ms-2"
          onClick={() => {
            callGetBaptismDetailsForPreview(baptism?.baptismId);
          }}
        >
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <i className="ri-eye-line"></i>
          )}
        </Button> */}
      </td>
      <GeneratedBaptismCertificateModal
        setShow={setGeneratedBaptismModal}
        show={generatedBaptismModal}
        baptismId={baptism?.baptismId}
        churchDetails={churchDetails}
        currentBaptism={currentBaptism}
      />
      <BaptismLinkCertificateModal
        show={baptismLinkCertModal}
        setShow={setBaptismLinkCertModal}
        baptismId={baptism?.baptismId}
        churchDetails={churchDetails}
        currentBaptism={currentBaptism}
      />
      <EditOutsiderBaptismDetailsModal
        show={editOutsiderEditBaptismModal}
        setShow={setEditOutsiderEditBaptismModal}
        currentBaptism={currentBaptism}
        callGetAllBaptism={callGetAllBaptism}
      />
    </tr>
  );
};

export default withAPIRequest(BaptismListRow);
