import React, { useState } from "react";
import { Badge, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../../constants/api";
import Swal from "sweetalert2";
import CustomTooltip from "../../../components/CustomTooltip";
import maleImg from "../../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../../assets/img/women_delhi_diocese_theme.png";

function getYearDifference(date1, date2) {
  // Convert string dates to Date objects
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  // Calculate difference in years
  const yearDiff = Math.floor(
    (secondDate - firstDate) / (1000 * 60 * 60 * 24 * 365.25)
  );

  return Math.abs(yearDiff); // Return absolute value to handle reversed dates
}

const DeceasedListRow = ({
  deceased,
  i,
  commonAPIRequest,
  callGetAllDeceased,
}) => {
  const [loading, setLoading] = useState(false);

  const callDeleteDeceasedApi = (id) => {
    let serviceParams = {
      api: `${API.DELETE_DECEASED}?deceasedId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          Swal.fire({
            title: "Deleted!",
            text: "Record has been deleted.",
            icon: "success",
          });
          callGetAllDeceased();
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <tr key={deceased?.deathId}>
      <th scope="row">{i + 1}</th>
      <td className="fw-bold">
        <img
          style={{ height: 36, width: 36 }}
          src={deceased?.gender === "Male" ? maleImg : femaleImg}
          className="img-fluid me-2"
          alt="..."
        />
        {deceased.memberId ? (
          <Link
            to={"/admin/member-details"}
            state={{
              memberId: deceased.memberId,
              back: true,
            }}
          >
            {deceased.middleName
              ? `${deceased.firstName} ${deceased.middleName} ${deceased.lastName}`
              : `${deceased.firstName} ${deceased.lastName}`}
          </Link>
        ) : deceased.middleName ? (
          `${deceased.firstName} ${deceased.middleName} ${deceased.lastName}`
        ) : (
          `${deceased.firstName} ${deceased.lastName}`
        )}
        {deceased?.memberId && (
          <Badge className="ms-2 fw-bold" bg="primary" pill>
            {/* <i className="ri-links-line me-1"></i> */}
            {getYearDifference(deceased?.dateOfBirth, deceased?.deathDate)}{" "}
            Years
          </Badge>
        )}
      </td>
      <td>{deceased?.gender}</td>
      <td>
        {new Date(deceased?.deathDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </td>
      <td>
        {deceased?.burialDate
          ? new Date(deceased?.burialDate).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })
          : "-"}
      </td>
      {/* <td>
        <Button
          variant="warning"
          onClick={() => {
            // Handle edit action
          }}
        >
          <i className="ri-edit-2-fill"></i>
        </Button>

        <CustomTooltip label={"Delete Record"} placement={"top"}>
          <Button
            onClick={() =>
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  callDeleteDeceasedApi(deceased.deceasedId);
                }
              })
            }
            variant="danger"
            className="ms-2"
          >
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <i className="ri-delete-bin-6-line"></i>
            )}
          </Button>
        </CustomTooltip>
      </td> */}
    </tr>
  );
};

export default withAPIRequest(DeceasedListRow);
