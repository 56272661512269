import axios from "axios";
const dashboardMenu = [
  {
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Feeds",
    link: "/admin/feeds",
    icon: "ri-rss-line",
  },
];

const applicationsMenu = [
  {
    label: "BEC Units",
    link: "/admin/zones",
    icon: "ri-map-pin-line",
  },
  {
    label: "Families",
    link: "/admin/families",
    icon: "ri-team-line",
  },
  {
    label: "Search Members",
    link: "/admin/search-members",
    icon: "ri-search-line",
  },
];

const financialsMenu = [
  {
    label: "CSF's",
    link: "/admin/csf",
    icon: "ri-wallet-3-line",
  },
  {
    label: "Contributions",
    link: "/admin/donations",
    icon: "ri-hand-coin-line",
  },
  {
    label: "2nd Collection",
    link: "/admin/diocese-donation",
    icon: "ri-open-arm-line",
  },
  {
    label: "Expenses",
    link: "/admin/expenses",
    icon: "ri-bank-card-2-fill",
  },
];

const certificatesMenu = [
  {
    label: "Sacraments",
    // link: "/admin/certificates",
    icon: "ri-file-user-line",
    submenu: [
      {
        label: "Baptism",
        link: "/admin/baptism-certificates",
      },
      {
        label: "Holy Communion",
        link: "/admin/holy-communion-certificates",
      },
      {
        label: "Holy Confirmation",
        link: "/admin/holy-confirmation-certificates",
      },
      {
        label: "Marriage",
        link: "/admin/marriage-certificates",
      },
      {
        label: "Annulment",
        link: "/admin/annulment-certificates",
      },
      {
        label: "Deceased",
        link: "/admin/deceased-list",
      },
    ],
  },
  {
    label: "Letters",
    link: "/admin/letters",
    icon: "ri-file-2-line",
  },
];

const settingsMenu = [
  {
    label: "Profile",
    link: "/admin/profile",
    icon: "ri-user-3-line",
  },
  {
    label: "Reset Password",
    link: "/admin/reset-password",
    icon: "ri-lock-2-line",
  },
  {
    label: "Logout",
    link: "/",
    icon: "ri-logout-box-line",
    replace: true,
    clickType: "logout",
  },
];

export {
  dashboardMenu,
  applicationsMenu,
  certificatesMenu,
  settingsMenu,
  financialsMenu,
};
