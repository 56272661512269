import * as Yup from "yup";

const dateFormatter = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const digitsOnly = (value) => /^\d+$/.test(value);

const alphabetsOnly = /^[-a-zA-Z][-a-zA-Z\.\'\(\) ]*$/;

export const BasicFamilyDetailsValidation = Yup.object().shape({
  zone: Yup.string().required("Required"),
  subZone: Yup.string().required("Required"),
  addressLine1: Yup.string().required("Required"),
  dateOfRegistration: Yup.date().max(
    new Date(),
    `Date needs to be on or before ${dateFormatter(new Date())}`
  ),
});

export const FamilyHeadBasciDetailsValidation = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  lastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  dob: Yup.date()
    .required("Required")
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      `H.O.F should be atleast 18 years or older.`
    ),
  fatherName: Yup.string().matches(
    alphabetsOnly,
    "Only alphabets are allowed."
  ),
  motherName: Yup.string().matches(
    alphabetsOnly,
    "Only alphabets are allowed."
  ),
  gender: Yup.string().required("Required"),
  occupation: Yup.string(),
  bloodGroup: Yup.string().required("Required"),
});

export const FamilyMemberBasicDetailsValidation = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  lastName: Yup.string()
    .required("Required")
    .matches(alphabetsOnly, "Only alphabets are allowed."),
  dob: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
  fatherName: Yup.string().matches(
    alphabetsOnly,
    "Only alphabets are allowed."
  ),
  motherName: Yup.string().matches(
    alphabetsOnly,
    "Only alphabets are allowed."
  ),
  relationToHead: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  occupation: Yup.string(),
  bloodGroup: Yup.string().required("Required"),
  marriageStatus: Yup.string().required("Required"),
});

export const BasicContactValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid")
    .max(10, "Atmost 10 - Digits")
    .min(10, "Atleast 10 - Digits")
    .notRequired()
    .nullable(),
  email: Yup.string().email("Invalid Email"),
  whatsappNumber: Yup.string()
    // .test("Digits only", "Digits only", digitsOnly)
    .matches(phoneRegExp, "Phone number is not valid")
    .max(10, "Atmost 10 - Digits")
    .min(10, "Atleast 10 - Digits")
    .notRequired()
    .nullable(),
});

export const CSFValidationSchema = Yup.object().shape({
  totalDonationAmount: Yup.string()
    .required("Required")
    .test("Digits only", "Digits only", digitsOnly),
  paymentMode: Yup.string().required("Required"),
  startYear: Yup.number().required("Required"),
  startMonth: Yup.number().required("Required"),
  endYear: Yup.number()
    .required("Required")
    .when(
      "startYear",
      (startYear, Yup) =>
        startYear && Yup.min(startYear, "End Year cannot be before start year")
    ),
  endMonth: Yup.number()
    .required("Required")
    .when(
      ["startYear", "endYear", "startMonth"],
      ([startYear, endYear, startMonth], Yup) =>
        startYear === endYear
          ? Yup.min(startMonth, "End Month cannot be before start month")
          : Yup.required()
    ),
  paidOn: Yup.date()
    .required("Required")
    .max(
      new Date(),
      `Date needs to be on or before ${dateFormatter(new Date())}`
    ),
});
