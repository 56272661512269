import { createSlice } from "@reduxjs/toolkit";

const DeceasedSlice = createSlice({
  name: "deceased",
  initialState: {
    present: false,
    deceasedList: [],
    totalCount: 0,
    pageNumber: 1,
  },
  reducers: {
    getDeceasedList(state, action) {
      state.deceasedList = action.payload;
      state.present = true;
    },
    getTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
    clearDeceasedList(state, action) {
      state.deceasedList = [];
      state.present = false;
    },
  },
});

export const DeceasedAction = DeceasedSlice.actions;
export const DeceasedReducer = DeceasedSlice.reducer;
