import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Header from "../../layouts/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import FamilyTablePlaceholder from "../../components/placeholders/FamilyTablePlaceholder";
import ZoneFamilyTablePlaceholder from "../../components/placeholders/ZoneFamilyTablePlaceholder";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import ZoneExcelReport from "../../components/excel-reports/ZoneExcelReport";
import { useDispatch, useSelector } from "react-redux";
import { ZonesAction } from "../../store/slices/ZonesSlice";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

const ZoneFamillies = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const { zoneFamilies, zoneFamililiesTotalCount, zoneFamiliesPageNumber } =
    useSelector((state) => state.zones);

  const [currentPageNumber, setCurrentPageNumber] = useState(
    zoneFamiliesPageNumber
  );

  const getAllFamilyDetailsApi = () => {
    let serviceParams = {
      api: `${API.GET_ALL_FAMILY}?pgNum=${zoneFamiliesPageNumber}&pgSize=10&zoneId=${location.state.zoneId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: null,
    };

    setLoading(true);

    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            ZonesAction.setZonesFamilies(
              result.payload.headDetails ? result.payload.headDetails : []
            )
          );
          dispatch(
            ZonesAction.setZoneFamiliesTotalCount(
              result.payload.totalCount ? result.payload.totalCount : 0
            )
          );
          setCurrentPageNumber(zoneFamiliesPageNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (location.state) {
      getAllFamilyDetailsApi();
    }
  }, [location.state]);

  useEffect(() => {
    if (currentPageNumber !== zoneFamiliesPageNumber) {
      getAllFamilyDetailsApi();
    }
  }, [zoneFamiliesPageNumber]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4">
        <h5
          id="section3"
          className="main-subtitle d-flex justify-content-between align-items-center"
        >
          <div>
            <Button
              onClick={() => navigate("/admin/zones")}
              className="me-2"
              variant="outline"
            >
              <i className="ri-arrow-left-line"></i>
            </Button>
            Families in {location.state.zoneName}
          </div>
          {zoneFamilies.length !== 0 && (
            <div className="d-none d-lg-inline">
              <ZoneExcelReport
                totalCount={zoneFamililiesTotalCount}
                zoneId={location.state.zoneId}
                zoneName={location.state.zoneName}
              />
            </div>
          )}
        </h5>
        {/* <p>
          Add <code>hover</code> props to enable a hover state on table rows
          within a tbody.
        </p> */}
        <Card className="card-example">
          <Card.Body className="d-lg-none pt-0 ">
            <Row className="g-2 g-xxl-3 mb-5">
              {zoneFamilies?.map((data, index) => (
                <Col sm="6" md="4" key={index}>
                  <Card className="card-people shadow mt-3">
                    <Card.Body>
                      <Badge
                        style={{ position: "absolute", top: 10, right: 10 }}
                        pill
                        className="fw-bold"
                        bg="primary"
                      >
                        Head Of Family
                      </Badge>
                      <div className="d-flex align-items-center">
                        <img
                          style={{ maxWidth: 48 }}
                          src={data.gender === "Female" ? femaleImg : maleImg}
                          className="img-fluid text-center"
                          alt="..."
                        />
                        <div className="ms-2">
                          <h6 className="mt-3 fw-bold text-primary">
                            <Link to="">
                              {data.middleName
                                ? `${data.firstName} ${data.middleName} ${data.lastName}`
                                : `${data.firstName} ${data.lastName}`}
                            </Link>
                          </h6>
                          <p className="mb-0 ">
                            {data.emailAddress ? data.emailAddress : "-"}
                          </p>
                          <p>{data?.phNumber ? data?.phNumber : "-"}</p>
                        </div>
                      </div>
                      <hr className="mt-0" />
                      <div>
                        <span style={{ fontSize: 12 }} className="fw-bold">
                          Address:
                        </span>
                        <p className="mb-0">{data?.address?.addressLine1}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
          <Card.Body className="d-none d-lg-inline">
            <Table hover className="mb-0">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Parishioner</th>
                  <th scope="col">Family ID</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email Address</th>
                </tr>
              </thead>
              {loading && <ZoneFamilyTablePlaceholder />}

              <tbody>
                {zoneFamilies.length !== 0 &&
                  !loading &&
                  zoneFamilies.map((data, i) => {
                    return (
                      <tr key={data.memberId}>
                        <th scope="row">
                          {(zoneFamiliesPageNumber - 1) * 10 + (i + 1)}
                        </th>
                        <td
                          style={{ cursor: "pointer" }}
                          className="text-primary fw-bold"
                          onClick={() => {
                            navigate("/admin/family-details", {
                              state: {
                                familyId: data.familyId,
                              },
                            });
                            localStorage.setItem(
                              "currentFamilyId",
                              data?.familyId
                            );
                            localStorage.setItem(
                              "currentChurchFamilyId",
                              data?.churchFamilyId
                            );
                          }}
                        >
                          <img
                            style={{ height: 36, width: 36 }}
                            src={data?.gender === "Male" ? maleImg : femaleImg}
                            className="img-fluid me-2"
                            alt="..."
                          />
                          {data?.firstName} {data?.middleName} {data?.lastName}
                          {data?.active ? (
                            <Badge className="ms-2 fw-bold" bg="success" pill>
                              Active
                            </Badge>
                          ) : (
                            <Badge className="ms-2 fw-bold" bg="danger" pill>
                              Inactive
                            </Badge>
                          )}
                        </td>
                        <td className=" text-primary">
                          {data?.churchFamilyId}
                        </td>
                        <td>{data?.phNumber}</td>
                        <td>{data?.emailAddress}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {!loading && zoneFamilies.length === 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3 className="text-center fw-bold">No Families Found</h3>
              </div>
            )}
          </Card.Body>
          <pre>
            {Math.ceil(zoneFamililiesTotalCount / 10) > 1 && (
              <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                <Pagination.Item
                  disabled={zoneFamiliesPageNumber === 1}
                  onClick={() =>
                    dispatch(ZonesAction.setZoneFamiliesPageNumber(1))
                  }
                >
                  First
                </Pagination.Item>

                {getPaginationRange(
                  Math.ceil(zoneFamililiesTotalCount / 10),
                  zoneFamiliesPageNumber,
                  3
                ).map((n, i) => {
                  return (
                    <Pagination.Item
                      key={i}
                      onClick={() => {
                        dispatch(ZonesAction.setZoneFamiliesPageNumber(n));
                      }}
                      active={zoneFamiliesPageNumber === n}
                    >
                      {n}
                    </Pagination.Item>
                  );
                })}

                <Pagination.Item
                  onClick={() => {
                    dispatch(
                      ZonesAction.setZoneFamiliesPageNumber(
                        Math.ceil(zoneFamililiesTotalCount / 10)
                      )
                    );
                  }}
                >
                  Last
                </Pagination.Item>
              </Pagination>
            )}
          </pre>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withAPIRequest(ZoneFamillies);
