import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../components/form/TextInput";
import TextArea from "../../components/form/TextArea";
import { useSelector } from "react-redux";

const BaptismFormStep3 = ({ formik }) => {
  const { churchDetails } = useSelector((state) => state?.church);
  const [showAlternateRef, setShowAlternateRef] = useState(false);

  useEffect(() => {
    if (churchDetails?.churchName) {
      formik.setFieldValue("baptismPlace", churchDetails?.churchName);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    setShowAlternateRef(e.target.checked);
    if (!e.target.checked) {
      // Clear the alternate reference number when unchecking
      formik.setFieldValue("alternateRegRefNumber", null);
    }
  };

  return (
    <Row className="g-3">
      <Col sm={12}>
        <TextInput
          label={"Baptism Place"}
          setFieldValue={formik.setFieldValue}
          keyword={"baptismPlace"}
          value={formik.values.baptismPlace}
          error={formik.errors.baptismPlace}
          touched={formik.touched.baptismPlace}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Minister's Name"}
          setFieldValue={formik.setFieldValue}
          keyword={"ministerName"}
          value={formik.values.ministerName}
          error={formik.errors.ministerName}
          touched={formik.touched.ministerName}
        />
      </Col>
      <Col sm={12}>
        <TextArea
          label={"Remark"}
          setFieldValue={formik.setFieldValue}
          keyword={"remark"}
          value={formik.values.remark}
          error={formik.errors.remark}
          touched={formik.touched.remark}
        />
      </Col>
      <Col sm={12}>
        <TextInput
          label={"Register Reference Number"}
          setFieldValue={formik.setFieldValue}
          keyword={"registerRefNumber"}
          value={formik.values.registerRefNumber}
          error={formik.errors.registerRefNumber}
          touched={formik.touched.registerRefNumber}
          type="number"
        />
      </Col>
      <Col sm={12}>
        <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="showAlternateRef"
              checked={showAlternateRef}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="showAlternateRef">
              I have an alternate reference number which will be shown on the
              certificate
            </label>
          </div>
        </div>
      </Col>
      {showAlternateRef && (
        <Col sm={12}>
          <TextInput
            label={"Alternate Reg. Ref. No."}
            setFieldValue={formik.setFieldValue}
            keyword={"alternateRegRefNumber"}
            value={formik.values.alternateRegRefNumber}
            error={formik.errors.alternateRegRefNumber}
            touched={formik.touched.alternateRegRefNumber}
            type="number"
          />
        </Col>
      )}
    </Row>
  );
};

export default BaptismFormStep3;
