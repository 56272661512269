import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form, Pagination, Table } from "react-bootstrap";
import { useFormik } from "formik";
import DeceasedListRow from "./deceased-components/DeceasedListRow";
import ZoneFamilyTablePlaceholder from "../../components/placeholders/ZoneFamilyTablePlaceholder";
import { DeceasedAction } from "../../store/slices/DeceasedSlice";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

const DeceasedList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);

  const { churchDetails } = useSelector((state) => state?.church);

  const dispatch = useDispatch();
  const { deceasedList, totalCount, present, pageNumber } = useSelector(
    (state) => state.deceased
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    // validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      //   const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
      //     return e.trim().length > 0;
      //   });
      //   fullNameArray.length === 1
      //     ? callSearchAnnulmentApi(fullNameArray[0], "")
      //     : callSearchAnnulmentApi(
      //         fullNameArray[0],
      //         fullNameArray[fullNameArray.length - 1]
      //       );
    },
  });

  // api to call annulment cert api start

  const callGetAllDeceased = () => {
    let serviceParams = {
      api: `${API.GET_ALL_DECEASED}?pgNum=${pageNumber}&pgSize=10`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setAnnulmentList(result?.payload?.basicAnnulmentDetailList);
          dispatch(
            DeceasedAction.getDeceasedList(
              result?.payload?.basicDeathDetailList
                ? result?.payload?.basicDeathDetailList
                : []
            )
          );
          dispatch(DeceasedAction.getTotalCount(result?.payload.totalCount));
          setCurrentPageNumber(pageNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call annulment cert api end

  useEffect(() => {
    if (!present) {
      callGetAllDeceased();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== pageNumber) {
      callGetAllDeceased();
    }
  }, [pageNumber]);

  console.log("presenrt", deceasedList);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4 ">
        <div className="d-flex mb-3 justify-content-between align-items-center">
          <div>
            <h5 id="section3" className="main-subtitle m-0">
              Deceased Parishioner's Archive
            </h5>
            <p>
              {" "}
              Recent Deceased Parishioner's Details in{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                {churchDetails?.churchName}
              </span>
            </p>
          </div>
          <Button
            variant="light"
            onClick={() => {
              callGetAllDeceased();
            }}
            style={{ height: 40 }}
          >
            <i className="ri-refresh-line"></i>
          </Button>
        </div>

        <div className="form-search py-2  ">
          <i className="ri-search-line"></i>
          <Form.Control
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            type="text"
            placeholder="e.g. Joseph George"
            value={formik.values.name}
          />
          <Button onClick={formik.handleSubmit} variant="primary">
            Search
          </Button>
        </div>
        <hr className="hr" />
        {/*  */}

        <Card.Footer className="d-none d-lg-inline">
          <pre>
            <div className="main-label-group mb-3">
              <label>Results</label>
              <div>
                {/* <Button
                  onClick={() => {
                    setAnnulmentFilterModalVisible(true);
                  }}
                  className="mx-3"
                  variant="outline-primary"
                >
                  Filter
                </Button> */}
                {/* <Button
                  className="mx-3"
                  onClick={() => {
                    callGetAllAnnulment();
                    formik.resetForm();
                  }}
                  variant="outline-primary"
                >
                  Reset
                </Button> */}

                {/* <Button
                  onClick={() => {
                    setFindMarriageModal(true);
                  }}
                  variant="primary"
                >
                  Add
                </Button> */}
              </div>
            </div>
            <Card className="card-annulment">
              <Card.Body>
                <Table hover className="mb-0">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Demise Date</th>
                      <th scope="col">Burial Date</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  {loading && <ZoneFamilyTablePlaceholder />}
                  {!loading && (
                    <tbody>
                      {deceasedList?.map((deceased, i) => (
                        <DeceasedListRow
                          deceased={deceased}
                          i={(pageNumber - 1) * 10 + i}
                          key={i}
                        />
                      ))}
                    </tbody>
                  )}
                </Table>

                <pre>
                  {Math.ceil(totalCount / 10) > 1 && (
                    <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                      <Pagination.Item
                        disabled={pageNumber === 1}
                        onClick={() =>
                          dispatch(DeceasedAction.setPageNumber(1))
                        }
                      >
                        Previous
                      </Pagination.Item>

                      {getPaginationRange(
                        Math.ceil(totalCount / 10),
                        pageNumber,
                        3
                      ).map((n, i) => {
                        return (
                          <Pagination.Item
                            key={i}
                            onClick={() => {
                              dispatch(DeceasedAction.setPageNumber(n));
                            }}
                            active={pageNumber === n}
                          >
                            {n}
                          </Pagination.Item>
                        );
                      })}

                      <Pagination.Item
                        onClick={() => {
                          dispatch(
                            DeceasedAction.setPageNumber(
                              Math.ceil(totalCount / 10)
                            )
                          );
                        }}
                      >
                        Next
                      </Pagination.Item>
                    </Pagination>
                  )}
                </pre>

                {!loading && deceasedList?.length === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3
                      style={{ backgroundColor: "#fff" }}
                      className="text-center py-3 fw-bold"
                    >
                      No Deceased Found
                    </h3>
                  </div>
                )}
              </Card.Body>
            </Card>
          </pre>
        </Card.Footer>

        {/*  */}
      </div>
    </React.Fragment>
  );
};

export default withAPIRequest(DeceasedList);
