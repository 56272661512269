import React from "react";
import churchLogo from "../../assets/img/logo-1.png";
import waterMark from "../../assets/img/baptism-watermark.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FontDancingRegular from "../../assets/fonts/dancingscript/DancingScript-Regular.ttf";
import FontMateSCRegular from "../../assets/fonts/matesc/MateSC-Regular.ttf";
import delhidioceselogo from "../../assets/img/logo-for-cert.png";

Font.register({
  family: "Dancing",
  fonts: [
    {
      src: FontDancingRegular,
    },
  ],
});

Font.register({
  family: "Mate",
  fonts: [
    {
      src: FontMateSCRegular,
    },
  ],
});

const BaptismCertificatePdf = ({ details, churchDetails }) => {
  // console.log("churchDetails", churchDetails?.logoUrl);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark */}
        {churchDetails?.watermarkUrl && (
          <View style={styles.watermarkContainer}>
            <Image
              style={{ width: "100%", height: "100%" }}
              src={churchDetails?.watermarkUrl}
            />
          </View>
        )}
        {/* Certificate Content */}
        <View style={styles.certContainer}>
          <div style={styles.borderGray}>
            <div style={styles.borderRed}>
              {/* Header */}
              <View>
                <Text
                  style={[
                    styles.valueText,
                    { position: "absolute", top: -10, left: -10, fontSize: 10 },
                  ]}
                >
                  Ref. No:{" "}
                  {details?.altRegReferenceNum
                    ? details?.altRegReferenceNum
                    : details?.regReferenceNum
                    ? details?.regReferenceNum
                    : "-"}
                </Text>

                <View style={styles.header}>
                  {/* <Image style={styles.image} src={churchLogo} /> */}
                  {churchDetails?.logoUrl && (
                    <Image style={styles.image} src={churchDetails?.logoUrl} />
                  )}
                  {/* <View style={{ height: 25 }} /> */}
                  <View
                    style={{
                      position: "absolute",
                      top: -15,
                      right: -15,
                      //   backgroundColor: "red",
                    }}
                  >
                    <Image
                      style={{ height: 72, width: "auto" }}
                      src={delhidioceselogo}
                    />
                  </View>
                  <View style={styles.headerText}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Mate",
                        marginBottom: 10,
                      }}
                    >
                      {churchDetails?.churchName}
                    </Text>
                    <Text
                      style={{
                        fontSize: 12,
                        marginVertical: 5,
                        fontFamily: "Mate",
                      }}
                    >
                      {churchDetails?.churchAddress?.addressLine1}
                    </Text>
                  </View>
                </View>

                <View>
                  <Text
                    style={{
                      textAlign: "center",
                      border: "2px solid #d4af37",
                      paddingVertical: 2,
                      margin: 0,
                      fontSize: 12,
                      fontFamily: "Mate",
                      paddingBottom: 8,
                    }}
                  >
                    Extract from the parochial register of{" "}
                    {churchDetails?.churchName}, Archdiocese Of Delhi
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      color: "#000",
                      textAlign: "center",
                      marginVertical: 20,
                      fontFamily: "Dancing",
                      fontSize: 32,
                    }}
                  >
                    Certificate Of Baptism
                  </Text>
                </View>

                {/* Body */}
                <View style={styles.content}>
                  {/* Dynamic content here */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.keyText}>Date of Baptism :</Text>
                      <Text style={styles.keyText}>Date of Birth or Age :</Text>
                      <Text style={styles.keyText}>Sex :</Text>
                      <Text style={styles.keyText}>Name :</Text>
                      <Text style={styles.keyText}>Surname :</Text>
                      <Text style={styles.keyText}>Father's Name :</Text>
                      <Text style={styles.keyText}>Mother's Name :</Text>
                      <Text style={styles.keyText}>Father's Nationality :</Text>
                      <Text style={styles.keyText}>His Domicile :</Text>
                      <Text style={styles.keyText}>His Profession :</Text>
                      <Text style={styles.keyText}>Godfather's Name :</Text>
                      <Text style={styles.keyText}>Godfather's Surname :</Text>
                      <Text style={styles.keyText}>His Domicile :</Text>
                      <Text style={styles.keyText}>God Mother's Name :</Text>
                      <Text style={styles.keyText}>Her Surname :</Text>
                      <Text style={styles.keyText}>Her Domicile :</Text>
                      <Text style={styles.keyText}>Place Of Baptism :</Text>
                      <Text style={styles.keyText}>Minister's Name :</Text>
                      <Text style={styles.keyText}>Holy Communion :</Text>
                      <Text style={styles.keyText}>Confirmation :</Text>
                      <Text style={styles.keyText}>Marriage :</Text>
                    </View>

                    <View style={{ marginLeft: 20 }}>
                      <Text style={styles.valueText}>
                        {details?.baptismDate
                          ? new Date(details?.baptismDate).toLocaleDateString(
                              "en-IN",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.dob
                          ? new Date(details?.dob).toLocaleDateString("en-IN", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.gender ? details?.gender : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {`${details?.firstName} ${details?.middleName}`}
                      </Text>
                      <Text style={styles.valueText}>{details?.lastName}</Text>
                      <Text style={styles.valueText}>
                        {details?.fatherName ? details?.fatherName : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.motherName ? details?.motherName : "-"}
                      </Text>

                      <Text style={styles.valueText}>
                        {details?.fatherNationality
                          ? details?.fatherNationality
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.fatherDomicile
                          ? details?.fatherDomicile
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.fatherProfession
                          ? details?.fatherProfession
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.godFatherName ? details?.godFatherName : "-"}
                      </Text>

                      <Text style={styles.valueText}>
                        {details?.godFatherSurName
                          ? details?.godFatherSurName
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.godFatherDomicile
                          ? details?.godFatherDomicile
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.godMotherName ? details?.godMotherName : "-"}
                      </Text>

                      <Text style={styles.valueText}>
                        {details?.godMotherSurName
                          ? details?.godMotherSurName
                          : "-"}
                      </Text>

                      <Text style={styles.valueText}>
                        {details?.godMotherDomicile
                          ? details?.godMotherDomicile
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.placeOfBaptism
                          ? details?.placeOfBaptism
                          : "-"}
                      </Text>
                      <Text style={styles.valueText}>
                        {details?.ministerName ? details?.ministerName : "-"}
                      </Text>

                      <Text style={[styles.valueText, { width: "75%" }]}>
                        {details?.holyCommunionRemark
                          ? details?.holyCommunionRemark
                          : "-"}
                      </Text>
                      <Text style={[styles.valueText, { width: "75%" }]}>
                        {details?.confirmationRemark
                          ? details?.confirmationRemark
                          : "-"}
                      </Text>
                      <Text style={[styles.valueText, { width: "75%" }]}>
                        {details?.marriageRemark
                          ? details?.marriageRemark
                          : "-"}
                      </Text>
                    </View>
                  </View>

                  <View style={{ marginTop: 8, marginBottom: 8 }}>
                    <Text style={styles.keyText}>Remark :</Text>
                    <Text style={styles.valueText}>
                      {details?.remarks ? details?.remarks : "-"}
                    </Text>
                  </View>

                  {/* Add more details as needed */}
                </View>
              </View>
              {/* Footer */}
              <View style={styles.footer}>
                <View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "Mate",
                      textAlign: "center",
                    }}
                  >
                    Fr. {churchDetails?.fatherName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#7e7e7e",
                      fontFamily: "Mate",
                      textAlign: "center",
                    }}
                  >
                    {new Date().toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Text>
                </View>

                <Text style={{ fontSize: 12, fontFamily: "Mate" }}>Seal</Text>
              </View>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    // padding: 50,
  },
  watermarkContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.15, // Adjust opacity as needed
    justifyContent: "center",
    alignItems: "center",
  },
  borderGray: {
    padding: 5,
    border: "3px solid #58595b",
    height: "100%",
  },
  borderRed: {
    border: "3px double #ce202f",
    padding: 20,
    height: "100%",
    justifyContent: "space-between",
  },
  certContainer: {
    border: 5,
    borderColor: "#d4af37",
    boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
    padding: 20,
    backgroundColor: "rgb(240, 229, 207, 0.5)",
    height: "100%",
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 10,
  },
  headerText: {
    textAlign: "center",
    width: "100%",
  },
  content: {
    textAlign: "left",
    marginTop: 24,
  },
  footer: {
    marginTop: 100,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    maxHeight: 130,
    maxWidth: 130,
    marginRight: 10,
  },
  keyText: {
    fontSize: 12,
    color: "#7e7e7e",
    marginBottom: 10,
    fontFamily: "Mate",
  },
  valueText: {
    fontSize: 12,
    color: "#000",
    textTransform: "capitalize",
    fontFamily: "Mate",
    marginBottom: 10,
  },
});

export default BaptismCertificatePdf;
