import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Accordion, Button, Card, Pagination, Table } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import SelectInput from "../../components/form/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useFormik } from "formik";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loading_animation_delhi_theme.json";
import noSearchAnimation from "../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { FinancialsAction } from "../../store/slices/FinancialsSlice";
import { Link } from "react-router-dom";
import { FamilyAction } from "../../store/slices/FamilySlice";
import CsfExcelReport from "../../components/excel-reports/CsfExcelReport";
import CsfLinkReceiptModal from "../../modals/csf/CsfLinkReceiptModal";
import NotAuthenticated from "../../pages/NotAuthenticated";
import { AuthContext } from "react-oauth2-code-pkce";

function generateArrayOfYears() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear - 10; year <= currentYear + 10; year++) {
    years.push({ label: year.toString(), value: year });
  }

  return years;
}

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

function sortByMonthWithZeroesFor12Months(arr) {
  // Create a new array with the sorted values
  const sortedArray = arr.slice().sort((a, b) => a.month - b.month);

  const result = [];

  for (let monthIndex = 1; monthIndex <= 12; monthIndex++) {
    const matchingObj = sortedArray.find((obj) => obj.month === monthIndex);
    result.push(matchingObj ? matchingObj.totalFund : 0);
  }

  return result;
}

const monthArray = [
  { label: "JAN", value: 1 },
  { label: "FEB", value: 2 },
  { label: "MAR", value: 3 },
  { label: "APR", value: 4 },
  { label: "MAY", value: 5 },
  { label: "JUN", value: 6 },
  { label: "JUL", value: 7 },
  { label: "AUG", value: 8 },
  { label: "SEP", value: 9 },
  { label: "OCT", value: 10 },
  { label: "NOV", value: 11 },
  { label: "DEC", value: 12 },
];

//  ---------------------- DATA ---------------------

const seriesLine = [
  {
    name: "CSF",
    data: [1, 41, 35, 51, 29, 82, 29, 91, 148, 234, 125, 212],
  },
];

//  ---------------------- DATA ---------------------

const CsfList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { churchDetails } = useSelector((state) => state?.church);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [csfReceiptModal, setCsfReceiptModal] = useState(false);
  const [currentCsfDetails, setCurrentCsfDetails] = useState();
  const [yearChanged, setYearChanged] = useState(true);

  const {
    csfList,
    csfTotalCount,
    csfListPresent,
    csfPageNumber,
    csfMonth,
    csfGraphDataPresent,
    csfGraphData,
  } = useSelector((state) => state.financial);
  const [currentPageNumber, setCurrentPageNumber] = useState(csfPageNumber);
  const { tokenData } = useContext(AuthContext);
  const readCsfPermission = tokenData?.permissions?.includes("READ_CSF");

  const formik = useFormik({
    initialValues: {
      month: csfMonth,
      currentYear: new Date().getFullYear(),
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const optionLine = {
    chart: {
      toolbar: {
        show: true,
        selection: true,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    title: {
      text: `CSF Collected by Month in ${formik.values?.currentYear}`,
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    colors: ["#912e2d"],
    tooltip: {
      y: {
        formatter: function (val) {
          return "₹ " + val + "";
        },
      },
    },
  };

  // call api for get all csf

  const getAllCsfsApi = () => {
    let serviceParams = {
      api: `${API.GET_CSF_SUMMARY}?pgNum=${csfPageNumber}&pgSize=10&month=${formik.values?.month}&year=${formik.values.currentYear}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          // setCsfs(result?.payload?.consolidatedSupportFundDetailList);
          dispatch(
            FinancialsAction.getCsfList(
              result?.payload?.consolidatedSupportFundDetailList
                ? result?.payload?.consolidatedSupportFundDetailList
                : []
            )
          );
          dispatch(
            FinancialsAction.getCsfTotalCount(result?.payload.totalCount)
          );
          setCurrentPageNumber(csfPageNumber);
          dispatch(FinancialsAction.setCsfMonth(formik.values?.month));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // call api for get all csf graph data

  const getAllCsfsGraphApi = () => {
    let serviceParams = {
      api: `${API.GET_CSF_GRAPH_SUMMARY}?year=${formik.values.currentYear}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            FinancialsAction.setCsfGraphData(
              result?.payload?.supportFundConsolidationList
            )
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!csfGraphDataPresent && readCsfPermission) {
      getAllCsfsGraphApi();
    }
  }, []);

  useEffect(() => {
    if (!csfListPresent && readCsfPermission) {
      getAllCsfsApi();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== csfPageNumber) {
      getAllCsfsApi();
    }
  }, [csfPageNumber]);

  useEffect(() => {
    if (formik.values?.month !== csfMonth) {
      if (csfPageNumber !== 1) {
        dispatch(FinancialsAction.setCsfPageNumber(1));
      } else {
        getAllCsfsApi();
      }
    }
  }, [formik.values?.month]);

  useEffect(() => {
    if (yearChanged) {
      getAllCsfsGraphApi();
      getAllCsfsApi();
      setYearChanged(false);
    }
  }, [formik.values?.currentYear]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        {!readCsfPermission ? (
          <NotAuthenticated />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 id="section3" className="main-subtitle m-0">
                  All Church Support Fund
                </h5>
                <p>
                  List of all the CSF of{" "}
                  <span className="fw-bold text-primary text-decoration-underline">
                    {churchDetails?.churchName}
                  </span>
                  .
                </p>
              </div>

              <div className="d-flex">
                <SelectInput
                  selectOptions={generateArrayOfYears()}
                  setFieldValue={formik.setFieldValue}
                  keyword={"currentYear"}
                  value={formik.values.currentYear}
                  error={formik.errors.currentYear}
                  touched={formik.touched.currentYear}
                  onChange={() => {
                    setYearChanged(true);
                  }}
                />
                <Button
                  variant="light"
                  onClick={() => {
                    getAllCsfsGraphApi();
                    getAllCsfsApi();
                  }}
                  style={{ height: 40 }}
                >
                  <i className="ri-refresh-line"></i>
                </Button>
              </div>
            </div>

            <Card className="card-csf">
              <Card.Body>
                <ReactApexChart
                  series={[
                    {
                      name: "CSF",
                      data: sortByMonthWithZeroesFor12Months(csfGraphData),
                    },
                  ]}
                  options={optionLine}
                  type="bar"
                  height={350}
                />
              </Card.Body>
              <Card.Footer>
                <pre>
                  <p>
                    CSF Collected For Each Month in {formik.values.currentYear}
                  </p>
                </pre>
              </Card.Footer>
            </Card>

            <Card className="card-csf-list">
              <Card.Body className="d-lg-none pt-0 ">
                <div
                  className="mb-3 mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <h4 style={{ fontSize: 16 }} className="fw-bold">
                      CSF's for the month of{" "}
                      {
                        monthArray?.find(
                          (month) => month.value == formik.values.month
                        )?.label
                      }
                    </h4>
                  </div>
                  <div
                    style={{ width: "30%" }}
                    className="d-flex justify-content-end"
                  >
                    <SelectInput
                      selectOptions={monthArray}
                      setFieldValue={formik.setFieldValue}
                      keyword={"month"}
                      value={formik.values.month}
                      error={formik.errors.month}
                      touched={formik.touched.month}
                    />
                  </div>
                </div>

                <hr />

                <Accordion defaultActiveKey="0">
                  {!loading &&
                    csfList?.map((csf, index) => {
                      return (
                        <Accordion.Item
                          style={{
                            border: "1px solid #A05152",
                            // backgroundColor: "#F1F0F4",
                          }}
                          className="mb-2"
                          eventKey={index}
                        >
                          <Accordion.Header
                          // style={{ backgroundColor: "#F1F0F4" }}
                          >
                            <div>
                              <h5 className="fw-bold mb-0">
                                ₹ {csf?.fundAmount}
                              </h5>
                              <p style={{ fontSize: 12 }} className="m-0">
                                {" "}
                                by {csf?.firstName} {csf?.middleName}{" "}
                                {csf?.lastName}
                              </p>
                            </div>
                          </Accordion.Header>
                          {/* <hr className="m-0" /> */}
                          <Accordion.Body>
                            <p className="mb-2 mt-2">
                              <strong>Donated By:</strong>
                              <span className="text-primary">
                                {" "}
                                {csf?.firstName} {csf?.middleName}{" "}
                                {csf?.lastName}
                              </span>
                            </p>

                            <p className="mb-2 mt-2">
                              <strong>Amount Donated:</strong>
                              <span className="text-primary">
                                {" "}
                                ₹ {csf?.fundAmount}
                              </span>
                            </p>

                            <p className="mb-2 mt-2">
                              <strong>Payment Mode:</strong>
                              <span className="text-primary">
                                {" "}
                                {csf?.paymentMode}
                              </span>
                            </p>

                            <p className="mb-2 mt-2">
                              <strong>Donated On:</strong>
                              <span className="text-primary">
                                {" "}
                                {new Date(
                                  csf?.collectionDate
                                ).toLocaleDateString("en-GB", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })}
                              </span>
                            </p>

                            <p className="mb-3 mt-2">
                              <strong>Txn. ID:</strong>
                              <span className="text-primary">
                                {" "}
                                {csf?.sysTransactionId}
                              </span>
                            </p>

                            <p
                              style={{
                                fontSize: 11,
                                position: "absolute",
                                right: 25,
                              }}
                              className="text-danger fw-bold"
                            >
                              *Receipt can only be generated on desktop.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>

                {!loading && csfList.length === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3 className="text-center fw-bold">No Csf's Found</h3>
                    <p className="text-center">Please Add CSF Details.</p>
                  </div>
                )}

                {loading && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 120, height: 120 }}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                    </div>

                    <p className="text-center">Loading...</p>
                  </div>
                )}

                {Math.ceil(csfTotalCount / 10) > 1 && (
                  <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                    <Pagination.Item
                      disabled={csfPageNumber === 1}
                      onClick={() =>
                        dispatch(FinancialsAction.setCsfPageNumber(1))
                      }
                    >
                      First
                    </Pagination.Item>

                    {getPaginationRange(
                      Math.ceil(csfTotalCount / 10),
                      csfPageNumber,
                      3
                    ).map((n, i) => {
                      return (
                        <Pagination.Item
                          key={i}
                          onClick={() => {
                            dispatch(FinancialsAction.setCsfPageNumber(n));
                          }}
                          active={csfPageNumber === n}
                        >
                          {i + 1}
                        </Pagination.Item>
                      );
                    })}

                    <Pagination.Item
                      onClick={() => {
                        dispatch(
                          FinancialsAction.setCsfPageNumber(
                            Math.ceil(csfTotalCount / 10)
                          )
                        );
                      }}
                    >
                      Last
                    </Pagination.Item>
                  </Pagination>
                )}
              </Card.Body>
              <Card.Body className="d-none d-lg-inline">
                <div
                  className="mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h4 className="fw-bold">
                      CSF's for the month of{" "}
                      {
                        monthArray?.find(
                          (month) => month.value === formik.values.month
                        )?.label
                      }
                    </h4>
                  </div>
                  <div className="d-flex">
                    <SelectInput
                      selectOptions={monthArray}
                      setFieldValue={formik.setFieldValue}
                      keyword={"month"}
                      value={formik.values.month}
                      error={formik.errors.month}
                      touched={formik.touched.month}
                    />
                    <CsfExcelReport
                      totalCount={csfTotalCount}
                      currentMonth={formik.values?.month}
                    />
                  </div>
                  {/* <Button
                onClick={() => {
                  setAddCsfModalVisible(true);
                }}
                variant="primary"
                className="ms-3"
              >
                Add CSF
              </Button> */}
                </div>

                <hr />

                {csfList?.length === 0 && !loading ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3 className="text-center fw-bold">No Csf's Found</h3>
                    <p className="text-center">Please Add CSF Details.</p>
                  </div>
                ) : loading ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 120, height: 120 }}
                        animationData={loadingAnimation}
                        loop={true}
                      />
                    </div>

                    <p className="text-center">Loading...</p>
                  </div>
                ) : (
                  <Table hover className="mb-0">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Paid On</th>
                        <th scope="col">Mode Of Payment</th>
                        <th scope="col">Receipt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {csfList?.map((csf, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              {(csfPageNumber - 1) * 10 + (index + 1)}
                            </th>
                            <td className="fw-bold">
                              <Link
                                onClick={() => {
                                  dispatch(
                                    FamilyAction.setFamilyId(csf.familyId)
                                  );
                                  localStorage.setItem(
                                    "currentFamilyId",
                                    csf?.familyId
                                  );
                                  localStorage.setItem(
                                    "currentChurchFamilyId",
                                    csf?.churchFamilyId
                                  );
                                }}
                                state={{
                                  familyId: csf.familyId,
                                  churchFamilyId: csf?.churchFamilyId,
                                }}
                                to={"/admin/family-details"}
                              >
                                {csf?.firstName} {csf?.middleName}{" "}
                                {csf?.lastName}
                              </Link>
                            </td>
                            <td>₹ {csf?.fundAmount} </td>
                            <td>
                              {" "}
                              {new Date(csf?.collectionDate).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                            </td>
                            <td>{csf?.paymentMode}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  setCurrentCsfDetails(csf);
                                  setCsfReceiptModal(true);
                                }}
                                variant="light"
                              >
                                <i className="ri-article-line"></i> Receipt
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <pre>
                  {Math.ceil(csfTotalCount / 10) > 1 && (
                    <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                      <Pagination.Item
                        disabled={csfPageNumber === 1}
                        onClick={() =>
                          dispatch(FinancialsAction.setCsfPageNumber(1))
                        }
                      >
                        First
                      </Pagination.Item>

                      {getPaginationRange(
                        Math.ceil(csfTotalCount / 10),
                        csfPageNumber,
                        3
                      ).map((n, i) => {
                        return (
                          <Pagination.Item
                            key={i}
                            onClick={() => {
                              dispatch(FinancialsAction.setCsfPageNumber(n));
                            }}
                            active={csfPageNumber === n}
                          >
                            {i + 1}
                          </Pagination.Item>
                        );
                      })}

                      <Pagination.Item
                        onClick={() => {
                          dispatch(
                            FinancialsAction.setCsfPageNumber(
                              Math.ceil(csfTotalCount / 10)
                            )
                          );
                        }}
                      >
                        Last
                      </Pagination.Item>
                    </Pagination>
                  )}
                </pre>
              </Card.Body>
            </Card>
          </>
        )}
        {/* <Footer /> */}
      </div>
      <CsfLinkReceiptModal
        setShow={setCsfReceiptModal}
        show={csfReceiptModal}
        csfDetails={currentCsfDetails}
        churchDetails={churchDetails}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(CsfList);
