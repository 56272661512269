import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import ZoneFamilyTablePlaceholder from "../../components/placeholders/ZoneFamilyTablePlaceholder";
import Lottie from "lottie-react";
import noSearchAnimation from "../../assets/lottie/../../assets/lottie/not_found_lottie_delhi_diocese_theme.json";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfirmationListRow from "./confirmation-components/ConfirmationListRow";
import ConfirmationCertificateFilterModal from "./confirmation-components/ConfirmationCertificateFilterModal";
import { useDispatch, useSelector } from "react-redux";
import { HolyConfirmationsAction } from "../../store/slices/HolyConfirmationsSlice";
import AddOutsiderHolyConfirmationDetailsModal from "../../modals/holy-confirmation/AddOutsiderHolyConfirmationDetailsModal";
import Swal from "sweetalert2";
import CustomTooltip from "../../components/CustomTooltip";
import maleImg from "../../assets/img/man_delhi_diocese_theme.png";
import femaleImg from "../../assets/img/women_delhi_diocese_theme.png";
import warningimg from "../../assets/img/warning.png";
import { Link } from "react-router-dom";

const MemberValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required").min(3),
});

function getPaginationRange(totalPages, currentPage, rangeSize = 5) {
  let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
  let end = start + rangeSize - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(end - rangeSize + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return pages;
}

const ConfirmationList = ({ commonAPIRequest }) => {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const [
    holyConfirmationFilterModalVisible,
    setHolyConfirmationFilterModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  const { holyConfirmations, totalCount, present, pageNumber } = useSelector(
    (state) => state.holyConfirmations
  );
  const { churchDetails } = useSelector((state) => state?.church);
  const [currentPageNumber, setCurrentPageNumber] = useState(pageNumber);

  const [outsiderConfirmationModal, setOutsiderConfirmationModal] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: MemberValidationSchema,
    onSubmit: (values) => {
      const fullNameArray = values.name.split(/(\s+)/).filter(function (e) {
        return e.trim().length > 0;
      });
      fullNameArray.length === 1
        ? callSearchHolyConfirmationApi(fullNameArray[0], "")
        : callSearchHolyConfirmationApi(
            fullNameArray[0],
            fullNameArray[fullNameArray.length - 1]
          );
    },
  });

  // api to call HOLY CONFIRMATION cert api start

  const callGetAllHolyConfirmation = () => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_LIST}?pgNum=${pageNumber}&pgSize=10&order=desc`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            HolyConfirmationsAction.getHolyConfirmations(
              result?.payload?.basicConfirmationDetailList
                ? result?.payload?.basicConfirmationDetailList
                : []
            )
          );
          dispatch(
            HolyConfirmationsAction.getTotalCount(result?.payload.totalCount)
          );
          setCurrentPageNumber(pageNumber);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call  HOLY CONFIRMATION cert api end

  // api to call Holy Confirmation cert api on date start

  const callGetAllHolyConfirmationByDate = (startDate, endDate) => {
    let serviceParams = {
      api: `${API.HOLY_CONFIRMATION_LIST}?pgNum=${pageNumber}&pgSize=10&startDate=${startDate}&endDate=${endDate}&order=desc`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            HolyConfirmationsAction.getHolyConfirmations(
              result?.payload?.basicConfirmationDetailList
                ? result?.payload?.basicConfirmationDetailList
                : []
            )
          );
          dispatch(
            HolyConfirmationsAction.getTotalCount(result?.payload.totalCount)
          );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to call holy Confirmation cert api on date end

  // api to search holy confiramtion details by name start

  const callSearchHolyConfirmationApi = (firstName, lastName) => {
    let serviceParams = {
      api: `${
        API.SEARCH_HOLY_CONFIRMATION
      }?firstName=${firstName.trim()}${`&lastName=${lastName.trim()}`}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    setLoading(true);
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        setLoading(false);
        try {
          dispatch(
            HolyConfirmationsAction.getHolyConfirmations(
              result?.payload ? result?.payload : []
            )
          );
          dispatch(HolyConfirmationsAction.getTotalCount(0));
        } catch (error) {
          console.log("error", error);
        }
      } else {
        setLoading(false);
      }
    });
  };

  // api to search holy confiramtion details by name end

  useEffect(() => {
    if (!present) {
      callGetAllHolyConfirmation();
    }
  }, []);

  useEffect(() => {
    if (currentPageNumber !== pageNumber) {
      callGetAllHolyConfirmation();
    }
  }, [pageNumber]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />

      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex mb-3 justify-content-between align-items-center">
          <div>
            <h5 id="section3" className="main-subtitle m-0">
              Holy Confirmation Certificate Archive
            </h5>
            <p>
              {" "}
              Recent Holy Confirmation Details in{" "}
              <span className="fw-bold text-primary text-decoration-underline">
                {churchDetails?.churchName}
              </span>
            </p>
          </div>
          <Button
            variant="light"
            onClick={() => {
              callGetAllHolyConfirmation();
            }}
            style={{ height: 40 }}
          >
            <i className="ri-refresh-line"></i>
          </Button>
        </div>
        {/* <p>
          Add <code>hover</code> props to enable a hover state on table rows
          within a tbody.
        </p> */}
        <div className="form-search py-2  ">
          <i className="ri-search-line"></i>
          <Form.Control
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            type="text"
            placeholder="e.g. Joseph George"
            value={formik.values.name}
          />
          <Button onClick={formik.handleSubmit} variant="primary">
            Search
          </Button>
        </div>
        <hr className="hr" />

        {/*  */}
        <div className="d-lg-none">
          <Row className="g-1 g-sm-2 g-xl-3 mb-5">
            <div className="main-label-group mb-3">
              <h3 className="fw-bold text-primary">Results</h3>
              <div>
                <Button
                  onClick={() => {
                    setHolyConfirmationFilterModalVisible(true);
                  }}
                  variant="outline-primary"
                >
                  Filter
                </Button>
              </div>
            </div>
            {holyConfirmations.map((confirmation) => (
              <Col xs="6" sm="4" md="3" xl key={confirmation?.baptismId}>
                <Card className="card-file">
                  <div
                    onClick={() => {
                      Swal.fire({
                        title: "Warning!",
                        text: "Certificates pdf's can only be generated / edited on the desktop.",
                        imageUrl: require("../../assets/img/on-desktop.png"),
                        imageWidth: 300,
                        imageHeight: 250,
                        imageAlt: "Custom image",
                      });
                    }}
                    className={"card-file-icon primary"}
                  >
                    {!confirmation?.memberId && (
                      <Badge
                        style={{ position: "absolute", top: 18, right: 18 }}
                        className="ms-2 fw-bold"
                        bg="secondary"
                        pill
                      >
                        Non Member
                      </Badge>
                    )}

                    <i className="ri-file-pdf-line"></i>
                  </div>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <img
                        style={{ height: 48, width: 48 }}
                        src={
                          confirmation?.gender === "Male" ? maleImg : femaleImg
                        }
                        className="img-fluid"
                        alt="..."
                      />

                      <h6 className="ms-2 mt-2 fw-bold">
                        {confirmation.memberId ? (
                          <Link
                            to={"/admin/member-details"}
                            state={{
                              memberId: confirmation.memberId,
                              back: true,
                            }}
                            className="link-02 fw-bold text-primary"
                          >
                            {confirmation.middleName
                              ? `${confirmation.firstName} ${confirmation.middleName} ${confirmation.lastName}`
                              : `${confirmation.firstName} ${confirmation.lastName}`}
                          </Link>
                        ) : confirmation.middleName ? (
                          `${confirmation.firstName} ${confirmation.middleName} ${confirmation.lastName}`
                        ) : (
                          `${confirmation.firstName} ${confirmation.lastName}`
                        )}
                        <div className="d-flex">
                          <span className="my-1">
                            {new Date(
                              confirmation?.confirmationDate
                            ).toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })}
                          </span>
                          {!confirmation?.confirmationDateAccurate && (
                            <CustomTooltip
                              label={"Date Might be Incorrect"}
                              placement={"top"}
                            >
                              <Badge className="ms-2 fw-bold p-0" bg="light">
                                <img
                                  height={18}
                                  src={warningimg}
                                  alt="warning"
                                />
                              </Badge>
                            </CustomTooltip>
                          )}
                        </div>
                      </h6>
                    </div>

                    {/* <span>{file.size}</span> */}
                  </Card.Body>
                  <hr className="my-1" />
                  <Card.Footer className=" pt-2 ps-2 bg-light">
                    <span>Reference Number: </span>
                    <p className="text-primary fw-bold">
                      {confirmation?.referenceNum}
                    </p>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
            <pre>
              {Math.ceil(totalCount / 10) > 1 && (
                <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                  <Pagination.Item
                    disabled={pageNumber === 1}
                    onClick={() =>
                      dispatch(HolyConfirmationsAction.setPageNumber(1))
                    }
                  >
                    First
                  </Pagination.Item>

                  {getPaginationRange(
                    Math.ceil(totalCount / 10),
                    pageNumber,
                    3
                  ).map((n, i) => {
                    return (
                      <Pagination.Item
                        key={i}
                        onClick={() => {
                          dispatch(HolyConfirmationsAction.setPageNumber(n));
                        }}
                        active={pageNumber === n}
                      >
                        {n}
                      </Pagination.Item>
                    );
                  })}

                  <Pagination.Item
                    onClick={() => {
                      dispatch(
                        HolyConfirmationsAction.setPageNumber(
                          Math.ceil(totalCount / 10)
                        )
                      );
                    }}
                  >
                    Last
                  </Pagination.Item>
                </Pagination>
              )}
            </pre>
            {!loading && holyConfirmations.length === 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                  }}
                  className="text-center"
                >
                  <Lottie
                    style={{ width: 180, height: 180 }}
                    animationData={noSearchAnimation}
                    loop={true}
                  />
                </div>
                <h3
                  style={{ backgroundColor: "#fff" }}
                  className="text-center py-3 fw-bold"
                >
                  No Holy Communion's Found
                </h3>
              </div>
            )}
          </Row>
        </div>
        {/*  */}

        <Card.Footer className="d-none d-lg-inline">
          <pre>
            <div className="main-label-group mb-3">
              <label>Results</label>
              <div>
                <Button
                  onClick={() => {
                    setHolyConfirmationFilterModalVisible(true);
                  }}
                  className="mx-3"
                  variant="outline-primary"
                >
                  Filter
                </Button>
                {/* <Button
                  className="mx-3"
                  onClick={() => {
                    callGetAllHolyConfirmation();
                    formik.resetForm();
                  }}
                  variant="outline-primary"
                >
                  Reset
                </Button> */}
                <Button
                  onClick={() => {
                    setOutsiderConfirmationModal(true);
                  }}
                  variant="primary"
                >
                  Add
                </Button>
              </div>
            </div>
            <Card className="card-confirmation">
              <Card.Body>
                <Table hover className="mb-0">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Confirmation Date</th>
                      <th scope="col">Reg. Ref. Num</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {loading && <ZoneFamilyTablePlaceholder />}
                  {!loading && (
                    <tbody>
                      {holyConfirmations?.map((confirmation, i) => (
                        <ConfirmationListRow
                          confirmation={confirmation}
                          i={(pageNumber - 1) * 10 + i}
                          key={i}
                          callGetAllHolyConfirmation={
                            callGetAllHolyConfirmation
                          }
                        />
                      ))}
                    </tbody>
                  )}
                </Table>

                <pre>
                  {Math.ceil(totalCount / 10) > 1 && (
                    <Pagination className="pagination-space pagination-filled mb-0 mt-3 d-flex justify-content-end">
                      <Pagination.Item
                        disabled={pageNumber === 1}
                        onClick={() =>
                          dispatch(HolyConfirmationsAction.setPageNumber(1))
                        }
                      >
                        First
                      </Pagination.Item>

                      {getPaginationRange(
                        Math.ceil(totalCount / 10),
                        pageNumber,
                        3
                      ).map((n, i) => {
                        return (
                          <Pagination.Item
                            key={i}
                            onClick={() => {
                              dispatch(
                                HolyConfirmationsAction.setPageNumber(n)
                              );
                            }}
                            active={pageNumber === n}
                          >
                            {n}
                          </Pagination.Item>
                        );
                      })}

                      <Pagination.Item
                        onClick={() => {
                          dispatch(
                            HolyConfirmationsAction.setPageNumber(
                              Math.ceil(totalCount / 10)
                            )
                          );
                        }}
                      >
                        Last
                      </Pagination.Item>
                    </Pagination>
                  )}
                </pre>

                {!loading && holyConfirmations?.length === 0 && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                      }}
                      className="text-center"
                    >
                      <Lottie
                        style={{ width: 180, height: 180 }}
                        animationData={noSearchAnimation}
                        loop={true}
                      />
                    </div>
                    <h3
                      style={{ backgroundColor: "#fff" }}
                      className="text-center py-3 fw-bold"
                    >
                      No Holy Confirmation's Found
                    </h3>
                  </div>
                )}
              </Card.Body>
              {/* <Card.Footer>
                <pre>
                  <code className="language-html">{`<Table hover className="mb-0">...</Table`}</code>
                </pre>
              </Card.Footer> */}
            </Card>
          </pre>
        </Card.Footer>
      </div>
      <ConfirmationCertificateFilterModal
        show={holyConfirmationFilterModalVisible}
        setShow={setHolyConfirmationFilterModalVisible}
        callGetAllHolyConfirmationByDate={callGetAllHolyConfirmationByDate}
      />
      <AddOutsiderHolyConfirmationDetailsModal
        show={outsiderConfirmationModal}
        setShow={setOutsiderConfirmationModal}
        callGetAllHolyConfirmation={callGetAllHolyConfirmation}
      />
    </React.Fragment>
  );
};

export default withAPIRequest(ConfirmationList);
